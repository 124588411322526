import React, { useState } from 'react';
import { AccordionLayout } from '..';
import groom from '../../Assets/icons/groom.png';
import PropTypes from 'prop-types';

const Accordion = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(1);

  return (
    <div className='flex flex-col'>
      <AccordionLayout
        title={data[0].title}
        index={1}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      >
        {data[0].content}
      </AccordionLayout>
      <AccordionLayout
        title={data[1].title}
        index={2}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      >
        {data[1].content}
      </AccordionLayout>
      <AccordionLayout
        title={data[2].title}
        index={3}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      >
        <div className='grid grid-cols-1 gap-2'>
          {data[2].content.map((item, i) => (
            <div
              className='flex border-2 w-52 items-center border-solid border-action rounded-lg'
              key={i}
            >
              <img src={groom} className='py-2 w-10 mx-1' />
              <h1>{item}</h1>
            </div>
          ))}
        </div>
      </AccordionLayout>
      <AccordionLayout
        title={data[3].title}
        index={4}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      >
        {console.log("data3", data[3].content)}
        {/* <div className='grid grid-cols-4 gap-1'>
          {data[3].content.map((item, i) => (
            <img src={item} key={i} className='h-24' />
          ))}
        </div> */}
        {
          data[3].content.map((item, i) => (
            <img src={item} alt='' key={i} className='h-24' />
          ))
        }
      </AccordionLayout>
      <AccordionLayout
        title={data[4].title}
        index={5}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      >
        {/* <div className='grid grid-cols-4 gap-1'>
          {data[4].content.map((item, i) => (
            <img src={item} key={i} className='h-24' />
          ))}
        </div> */}
        {
          data[4].content.map((item, i) => (
            <img src={item} alt='' key={i} className='h-24' />
          ))
        }
      </AccordionLayout>
    </div>
  );
};

Accordion.defaultProps = {
  data: [],
};

Accordion.propTypes = {
  data: PropTypes.array,
};

export default Accordion;
