import React, { useEffect } from 'react'
import { imageUpload } from '../../Functions/ImageUpload'
import ReactGoogleAutocomplete from 'react-google-autocomplete'
import { VscLocation } from 'react-icons/vsc'

export default function Stage3({ parentData, handleInput, setParentData }) {
  const [formData, setFormData] = React.useState({
    address: '',
    city: '',
    pinCode: '',
  })
  const [mobileError, setMobileError] = React.useState(false)

  useEffect(() => {
    console.log(formData)
    if (formData.address && formData.city && formData.pinCode) {
      setParentData({
        ...parentData,
        Address1: formData.address,
        City: formData.city,
        PinCode: formData.pinCode,
      })
    }
  }, [formData])

  return (
    <>
      <h4 className="text-lg mt-5">
        <b>Enter Pet Parent&apos;s details </b>
      </h4>
      <div className="mt-5 mb:ml-3 lg:ml-0">
        <form
          action="https://fglpg001.futuregenerali.in/CoreApi/api/SaveDetails/AddProposer"
          method="POST"
          className="flex flex-wrap justify-between"
        >
          <div className="flex flex-col w-full lg:w-[450px]">
            <p>
              Parent&apos;s Name{' '}
              <span className="text-red-800 text-lg ">*</span>
            </p>
            <input
              value={parentData.parentName}
              onChange={handleInput}
              type="text"
              name="parentName"
              className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full lg:w-[450px]"
              required
            />
          </div>

          <div className="flex flex-col">
            <label>
              Mobile Number <span className="text-red-800 text-lg ">*</span>{' '}
            </label>
            <div className="flex w-full lg:w-[450px]">
              <input
                value="+91"
                type="text"
                className="border-1 border-[#1eaf9c] rounded-l-md font-roboto mt-2 p-4 py-2 max-w-[20%] lg:w-[60px] "
                disabled
              />
              <input
                value={parentData.MobileNo}
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    handleInput(e)
                  }
                }}
                type="number"
                name="MobileNo"
                maxLength="10"
                className="border-1 border-[#1eaf9c] rounded-r-md font-roboto mt-2 p-4 py-2 w-[80%] lg:w-[390px] "
                required
                onBlur={() => {
                  if (parentData.MobileNo.length !== 10) {
                    setMobileError(true)
                  } else {
                    setMobileError(false)
                  }
                }}
              />
            </div>
            {mobileError && (
              <p className="text-red-800 text-sm mt-2">
                Please enter a valid mobile number
              </p>
            )}
          </div>

          <div className="flex flex-col mt-3   w-full lg:w-[450px]">
            <label>
              Email ID <span className="text-red-800 text-lg ">*</span>
            </label>
            <input
              value={parentData.EmailId}
              onChange={handleInput}
              type="email"
              name="EmailId"
              className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full lg:w-[450px] "
              required
            />
          </div>

          <div className="flex flex-col  mt-3 w-full lg:w-[450px]">
            <label htmlFor="Gender">
              Gender <span className="text-red-800 text-lg ">*</span>
            </label>
            <select
              // value={parentData.Gender}
              // onChange={handleInput}
              onChange={(e) => {
                console.log(e.target.value)
                setParentData({
                  ...parentData,
                  Gender: e.target.value,
                })
              }}
              type="text"
              name="Gender"
              className="border-1 border-[#1eaf9c]  mt-2 rounded-md p-4 py-2 w-full lg:w-[450px] bg-white  "
              required
            >
              <option
                className="hover:bg-[#1eaf9c] focus:bg-[#1eaf9c]"
                value="M"
              >
                Male
              </option>
              <option value="F">Female</option>
            </select>
          </div>

          <div className="flex flex-col mt-3   w-full lg:w-[450px] custom-date-picker">
            <label>
              Date Of Birth <span className="text-red-800 text-lg ">*</span>
            </label>
            <input
              value={parentData.DOB}
              onChange={handleInput}
              type="date"
              name="DOB"
              className="relative border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full lg:w-[450px] "
              required
            />
          </div>

          {/* <div className="flex flex-col my-4 w-full lg:w-[450px]">
            <label htmlFor="Address1">
              Address 1 <span className="text-red-800 text-lg ">*</span>
            </label>
            <input
              value={parentData.Address1}
              onChange={handleInput}
              type="text"
              name="Address1"
              className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full lg:w-[450px] "
              required
            />
          </div> */}

          <div className="flex flex-col my-4 w-full lg:w-[450px]">
            <label htmlFor="Address1">
              Address 1 <span className="text-red-800 text-lg ">*</span>
            </label>
            <div
              className={`border-1 border-[#1eaf9c] flex overflow-hidden my-1 first:mt-0 font-number rounded-md last:mb-0 relative h-10 w-full `}
            >
              <ReactGoogleAutocomplete
                apiKey={process.env.REACT_APP_MAP_KEY}
                options={{
                  componentRestrictions: { country: 'in' },
                  types: ['establishment'],
                }}
                onPlaceSelected={(place) => {
                  var address = place.address_components
                  var zipcode = address[address.length - 1].long_name
                  address.forEach((element) => {
                    if (element.types[0] === 'locality') {
                      setFormData({
                        city: element.long_name,
                        pinCode: zipcode,
                        address: place.formatted_address,
                      })
                    }
                  })
                }}
                id={'address_location'}
                className="p-4 py-2 w-full placeholder-transparent "
                // defaultValue={address}
              />
            </div>
          </div>

          <div className="flex flex-col my-4 w-full lg:w-[450px]">
            <label htmlFor="Address2">Address 2 (Optional) </label>
            <input
              value={parentData.Address2}
              onChange={handleInput}
              type="text"
              name="Address2"
              placeholder="Street / Building / House"
              className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full lg:w-[450px] "
            />
          </div>

          <div className="flex flex-col my-4 w-full lg:w-[450px]">
            <label htmlFor="Address3">Address 3 (Optional)</label>
            <input
              value={parentData.Address3}
              onChange={handleInput}
              type="text"
              name="Address3"
              placeholder="Landmark"
              className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full lg:w-[450px] "
            />
          </div>

          <div className="flex flex-col mt-3 w-full lg:w-[450px]">
            <label htmlFor="City">
              City <span className="text-red-800 text-lg ">*</span>
            </label>
            <input
              value={parentData.City}
              onChange={handleInput}
              type="text"
              name="City"
              className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full lg:w-[450px] "
              required
            />
          </div>

          <div className="flex flex-col my-4 w-full lg:w-[450px]">
            <label htmlFor="PinCode">
              Pin Code <span className="text-red-800 text-lg ">*</span>
            </label>
            <input
              value={parentData.PinCode}
              onChange={handleInput}
              type="number"
              name="PinCode"
              className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full lg:w-[450px] "
              required
            />
          </div>

          <div className="flex flex-col my-4 w-full lg:w-[450px]">
            <label htmlFor="PanCardNumber">
              PAN Card Number <span className="text-red-800 text-lg ">*</span>
            </label>
            <input
              value={parentData.PanCardNumber}
              onChange={handleInput}
              type="text"
              name="PanCardNumber"
              className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full lg:w-[450px]"
              maxLength={10}
              required
            />
          </div>
          <div className="flex flex-col my-4 w-full lg:w-[450px]">
            <label htmlFor="PanCardNumber">
              PAN Card Photo <span className="text-red-800 text-lg ">*</span>
            </label>
            <div className="flex mt-3 rounded-md gap-7 border-1 border-[#1eaf9c]">
              <input
                type="file"
                placeholder="Browse Files (File should be less than 5MB)"
                accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                name="CertificateOfGoodHealth"
                onChange={async (e) => {
                  const imageUrl = await imageUpload(
                    'petInsurance',
                    e.target.files[0],
                  )

                  setParentData({
                    ...parentData,
                    PanCardPhoto: imageUrl,
                  })
                }}
              />
            </div>
          </div>

          <div className="flex flex-col my-4 w-full lg:w-[450px]">
            <label htmlFor="Occupation">
              Occupation <span className="text-red-800 text-lg ">*</span>
            </label>
            <input
              value={parentData.Occupation}
              onChange={handleInput}
              type="text"
              name="Occupation"
              className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full lg:w-[450px]"
              required
            />
          </div>

          <div className="flex flex-col my-4 w-full lg:w-[450px]">
            <label htmlFor="MaritalStatus">
              Marital Status <span className="text-red-800 text-lg ">*</span>
            </label>
            <input
              value={parentData.MaritalStatus}
              onChange={handleInput}
              type="text"
              name="MaritalStatus"
              className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full lg:w-[450px]"
              // onChange={(e) => {
              //     console.log(e.target.value)
              // }}
              required
            />
          </div>
        </form>
      </div>
    </>
  )
}
