import React, { useEffect, useState } from 'react'
import { GradientButton } from '..'
import PropTypes from 'prop-types'
import axios from 'axios'
import { addPetInsurance } from '../../Redux/actions/petInsuranceActions'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useRollbar } from '@rollbar/react'

const PayWithRazorPay = ({ amount, firstname, email, phone, dataToBeSent, dataForFG, setDataForFG, loading, setLoading, ckycDetails, proposalId }) => {
  const rollbar = useRollbar()
  console.log('------->', ckycDetails)

  const key = process.env.REACT_APP_PAYU_KEY
  const PAY_U_BASE_URL = process.env.REACT_APP_PAYU_API
  const surl = process.env.REACT_APP_S_URL
  const furl = process.env.REACT_APP_S_URL
  const HASH_URL = process.env.REACT_APP_HASH_URL

  const [hast, setHast] = useState('')
  // const [loading, setLoading] = useState(false)
  const [txnid, setTxnid] = useState(Math.floor(Math.random() * 1000000000))
  let productinfo = 'pet_insurance'

  useEffect(() => {
    axios
      .post(HASH_URL, {
        txnid,
        amount,
        productinfo,
        firstname,
        email,
      })
      .then((res) => {
        setHast(res.data)
        setDataForFG((prev) => {
          return { ...prev, ReferNo: `${txnid}` }
        })
      })
      .catch((err) => {
        rollbar.error('error in hash payu', err)
      })
  }, [])

  useEffect(() => {
    console.log('dataForFG', dataForFG)
  }, [dataForFG])

  const createFormDataAndSubmit = () => {
    console.log('please wait while we are redirecting to payment gateway')
    const form = document.createElement('form')
    form.setAttribute('method', 'post')
    form.setAttribute('action', PAY_U_BASE_URL)
    form.setAttribute('name', 'payuForm')
    form.setAttribute('id', 'payuForm')
    form.setAttribute('style', 'display: none')

    const input1 = document.createElement('input')
    input1.setAttribute('type', 'hidden')
    input1.setAttribute('name', 'key')
    input1.setAttribute('value', key)
    form.appendChild(input1)

    const input2 = document.createElement('input')
    input2.setAttribute('type', 'hidden')
    input2.setAttribute('name', 'hash')
    input2.setAttribute('value', hast)
    form.appendChild(input2)

    const input3 = document.createElement('input')
    input3.setAttribute('type', 'hidden')
    input3.setAttribute('name', 'txnid')
    input3.setAttribute('value', txnid)
    form.appendChild(input3)

    const input4 = document.createElement('input')
    input4.setAttribute('type', 'hidden')
    input4.setAttribute('name', 'amount')
    input4.setAttribute('value', amount)
    form.appendChild(input4)

    const input5 = document.createElement('input')
    input5.setAttribute('type', 'hidden')
    input5.setAttribute('name', 'productinfo')
    input5.setAttribute('value', productinfo)
    form.appendChild(input5)

    const input6 = document.createElement('input')
    input6.setAttribute('type', 'hidden')
    input6.setAttribute('name', 'firstname')
    input6.setAttribute('value', firstname)
    form.appendChild(input6)

    const input7 = document.createElement('input')
    input7.setAttribute('type', 'hidden')
    input7.setAttribute('name', 'email')
    input7.setAttribute('value', email)
    form.appendChild(input7)

    const input8 = document.createElement('input')
    input8.setAttribute('type', 'hidden')
    input8.setAttribute('name', 'phone')
    input8.setAttribute('value', phone)
    form.appendChild(input8)

    const input9 = document.createElement('input')
    input9.setAttribute('type', 'hidden')
    input9.setAttribute('name', 'surl')
    input9.setAttribute('value', surl)
    form.appendChild(input9)

    const input10 = document.createElement('input')
    input10.setAttribute('type', 'hidden')
    input10.setAttribute('name', 'furl')
    input10.setAttribute('value', furl)
    form.appendChild(input10)

    document.body.appendChild(form)
    //timeouts are used to make sure that the form is created before submitting
    setTimeout(() => {
      form.submit()
      // setLoading(false)
    }, 5000)
  }

  const submitData = (e) => {
    console.log('submit')
    setLoading(true)
    console.log('dataToBeSent', dataToBeSent)
    console.log('ckycDetails', ckycDetails)
    axios
      .post('https://us-central1-pawpurrfect-stage.cloudfunctions.net/createQuoate', {
        ...dataForFG,
        CKYCREFNO: ckycDetails ? '' : proposalId,
        CKYCNO: ckycDetails ? ckycDetails : '',
      })
      .then((res) => {
        console.log('res', res.data)
        if (res.data.ResponseFlag === 1) {
          axios
            .post('https://us-central1-pawpurrfect-stage.cloudfunctions.net/createInsuranceData', {
              ...dataForFG,
              txnid: `${txnid}`,
              created_date: new Date(),
              productinfo,
              ClientID: res.data.ClientID,
              ReceiptNo: res.data.ReceiptNo,
              WinNo: res.data.WinNo,
              quoteID: res.data.quoteID,
              PanCardPhoto: dataToBeSent.PanCardPhoto,
              vaccinationsProvided: dataToBeSent.vaccinationsProvided,
              Status: 'pending',
              TransactionStatus: 'pending',
              CKYCREFNO: ckycDetails ? '' : proposalId,
              CKYCNO: ckycDetails ? ckycDetails : '',
            })
            .then((res) => {
              createFormDataAndSubmit()
            })
            .catch((e) => console.log('error in saving data ', e))
        } else {
          setLoading(false)
          toast.error('Something went wrong')
        }
      })
      .catch((err) => {
        console.log('err', err)
        setLoading(false)
      })
  }

  return <GradientButton title="PAY NOW" className="w-full mt-7" onPress={submitData} loading={loading} />
}

PayWithRazorPay.defaultProps = {
  onComplete: () => {},
  startTransaction: () => {},
}

PayWithRazorPay.propTypes = {
  onComplete: PropTypes.func,
  amount: PropTypes.number,
  startTransaction: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
}

export default PayWithRazorPay
