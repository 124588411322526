import React from "react";
import PropTypes from "prop-types";
import  SecondaryModal  from "../Components/HOC/SecondaryModal";
import PropagateLoader from 'react-spinners/PropagateLoader'

const LoadingModel = ({  modalVisibility,  }) => {
  return (
    <SecondaryModal visibilty={modalVisibility}>
      <div className="relative py-12 lg:px-28 flex-auto rounded">
        <div className="my-2">
          {/* <p className="text-dark text-md font-semibold leading-relaxed text-center">
            Invalid Request!
          </p> */}
          {/* <p className="text-dark text-md leading-relaxed text-center">
           Please wait while we are processing your request.
          </p> */}
          <PropagateLoader color="#f4a42c" size={20} />
        </div>
        <div className="flex justify-center mt-8 gap-3">
          {/* <GradientButton onPress={onPressBack} title="BACK" /> */}
        </div>
      </div>
    </SecondaryModal>
  );
};
LoadingModel.propTypes = {
  modalVisibility: PropTypes.bool.isRequired,
  //onPressBack: PropTypes.func.isRequired,
};

export default LoadingModel;
