/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import axios from 'axios'
import { toast } from 'react-toastify'
//& Screen
import Stage3 from '../Components/InsuranceScreen/stage3'
import Stage2 from '../Components/InsuranceScreen/stage2'
import Stage4 from '../Components/InsuranceScreen/stage4'
//& Components
import TopButtonsInsurance from '../Components/PetPageComponents/TopButtonsInsurance'
import { Container, GradientButton } from '../Components'
import { INSURANCE_OPTIONS, petInsuranceAdddOns } from '../Constants/Constants'
import { PetProfileCard } from '../Components'
import { PayWithRazorPay } from '../Components/RequestPageComponents/..'
//& Functions
import InsuranceModelAndPetSi from '../Modal/InsuranceModelAndPetSi'
import { LoadingModel } from '../Modal'
import { useRollbar } from '@rollbar/react'

function PetInsurance() {
  const navigate = useNavigate()
  const rollbar = useRollbar()

  const {
    pet: { pets },
  } = useSelector(({ pet }) => ({ pet }))
  const { auth } = useSelector(({ auth }) => ({ auth }))

  const { petId: petID } = useParams()
  const {proposalId : proposalIdFromParams } = useParams()
 
  const index = pets.findIndex((element) => {
    return element.docid == petID
  })
  const { state } = useLocation()
  const proposalId = state?.ckycDetails?.proposal_id || proposalIdFromParams
  const ckycNumber = state?.ckycDetails?.result.ckyc_number

  const [stage, setStage] = useState(1)
  const [petDetails, setPetDetails] = useState()
  const [petOwnerDetails, setPetOwnerDetails] = useState()
  const [quoteID, setQuoteID] = useState('**********')
  const [amountDetails, setAmountDetails] = useState()
  const [coverAmountOption, setCoverAmountOption] = useState([])
  const [sumCoverAmountAndAddOns, setSumCoverAmountAndAddOns] = useState(0)
  const [totalCoverAmount, setTotalCoverAmount] = useState()
  const [additionalService, setAdditionalService] = useState([])
  const [AddOns, setAddOns] = useState(0)
  const [Insurance_Premium, SetInsurance_Premium] = useState(0)
  const [gst, setGst] = useState(0)
  const [gatewayCharge, setGatewayCharge] = useState(0)
  const [finalPremium, setFinalPremium] = useState(0)
  const [openPetInsuranceModal, setOpenPetInsuranceModal] = useState(false)
  const [nextBtn, setNextBtn] = useState(true)
  const [petSI, setPetSI] = useState()
  const [loading, setLoading] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)

  const [formData, setFormData] = useState({
    DistinguishingFeature: '',
    TagNo: '',
    IdentificationMark: '',
    PetValue: '',
    healthy: 'No',
    commercial_purpose: 'No',
    CertificateOfGoodHealth: '',
    PreExisting_disease: 'No',
    already_insured: 'Insured',
    NameOfTheInsuranceCompany: '',
    PolicyNumber: '',
    vaccinationsProvided: {
      Rabies: false,
      Leptospirosis: false,
      Distemper: false,
      Parainfluenza: false,
      Hepatitis: false,
      Parvovirus: false,
      Adeno_virus: false,
      Corona: false,
    },
    image1: '',
    image2: '',
    image3: '',
    image4: '',
    ownAnyOtherDog: 'No',
    VeterinaryDoctorForYourPet: 'No',
    LostAnyPet: 'No',
    FreeFromVice: 'No',
  })

  const [disclaimer, setDisclaimer] = useState({
    first: false,
    second: false,
    third: true,
  })

  const [parentData, setParentData] = useState({
    parentName: '',
    MobileNo: '',
    EmailId: '',
    Gender: 'M',
    Address1: '',
    City: '',
    PinCode: null,
    PanCardNumber: '',
    Occupation: '',
    MaritalStatus: '',
    Address2: '',
    Address3: '',
    PanCardPhoto: '',
    DOB: '',
  })
  const [dataToBeSent, setDataToBeSent] = useState({})
  const [dataForFG, setDataForFG] = useState({})
  useEffect(() => {
    const today = new Date()
    const yyyy = today.getFullYear()
    let mm = today.getMonth() + 1 // Months start at 0!
    let dd = today.getDate()

    if (dd < 10) dd = '0' + dd
    if (mm < 10) mm = '0' + mm

    const formattedToday = dd + '/' + mm + '/' + yyyy
    if (stage === 4) {
      //$ Data to be sent to be Stored in Firebase
      const finalGST = Math.round(sumCoverAmountAndAddOns * 0.18)
      const gateWayCharges = Math.round((sumCoverAmountAndAddOns + Math.round(sumCoverAmountAndAddOns * 0.18)) * 0.02)
      setDataToBeSent({
        ...formData,
        ...parentData,
        PetID: petID,
        disclaimer: disclaimer,
      })
      //$ Data for FG Api
      let breed = 'large'
      const petBreedSmall = ['kg_0_1', 'kg_1_3', 'kg_3_5', 'kg_5_10']
      const petBreedMedium = ['kg_10_20']
      const petBreedLarge = ['kg_20_40']
      if (petBreedSmall.includes(petDetails.weight)) {
        breed = 'small'
      } else if (petBreedMedium.includes(petDetails.weight)) {
        breed = 'medium'
      } else if (petBreedLarge.includes(petDetails.weight)) {
        breed = 'large'
      } else {
        breed = 'Giant'
      }

      setDataForFG({
        proposerName: `${parentData.parentName}`,
        MobileNo: `${parentData.MobileNo}`,
        EmailID: `${parentData.EmailId}`,
        Address1: `${parentData.Address1}`,
        Address2: `${parentData.Address2}`,
        Address3: `${parentData.Address3}`,
        City: `${parentData.City}`,
        StateCode: `${parentData.City}`,
        PinCode: `${parentData.PinCode}`,
        DOB: `${parentData.DOB}`,
        Gender: `${parentData.Gender}`,
        PANCard: `${parentData.PanCardNumber}`,
        SumGrossTotal: `${sumCoverAmountAndAddOns}`,
        SumGST: `${Math.round(sumCoverAmountAndAddOns * 0.18)}`,
        SumPremium: `${finalPremium}`,
        ReceiptAmt: `${finalPremium}`,
        ReferNo: 'pay_t09vbQVut2Urkr',
        // create date in dd/mm/yyyy format
        TranDate: `${formattedToday}`,
        gogreen: disclaimer.third ? 'Y' : 'N', //Y or N,
        Veterinary: formData.VeterinaryDoctorForYourPet === 'Yes' ? 'Y' : 'N', //Y or N access to a Veterinary Doctor?
        // DecIns: 'N', //Y or N Declined insurance of any of your pet dogs?
        // DecRen: 'N', //Y or N Declined to renew the insurance?
        // IncPrem: 'Y', //Y or N increased your premium
        PetDetailsList: [
          {
            PetName: petDetails?.name,
            BreedCategory: breed,
            Breed: petDetails?.breed,
            AgeMonth: `${Number(petDetails?.age) * 12 + Number(petDetails?.months)}`,
            Gender: `${parentData.Gender}`,
            PetWeight: `${petDetails?.weight}`,
            Identification: `${formData.IdentificationMark}`,
            Features: `${formData.DistinguishingFeature}`,
            TagNo: `${formData.TagNo}`,
            PetSI: `${petSI}`,
            PetValue: `${formData.PetValue}`,
            Q1Answer: `${formData.healthy}`,
            Q2Answer: `${formData.commercial_purpose}`,
            Q3Answer: `${formData.PreExisting_disease}`,
            Q4Answer: `${formData.already_insured}`,
            Q5Answer: `${formData.NameOfTheInsuranceCompany}`,
            Q6Answer: `${formData.PolicyNumber}`,
            Q7Answer: `${formData.ownAnyOtherDog}`,
            Q8Answer: `${formData.VeterinaryDoctorForYourPet}`,
            Q9Answer: `${formData.LostAnyPet}`,
            Q10Answer: `${formData.FreeFromVice}`,
            Photo1: `${formData.image1}`,
            Photo2: `${formData.image2}`,
            Photo3: `${formData.image3}`,
            Photo4: `${formData.image4}`,
            Doc: `${formData.CertificateOfGoodHealth}`,
            Premium: `${sumCoverAmountAndAddOns}`,
            GST: `${Math.round(sumCoverAmountAndAddOns * 0.18)}`,
            TotalPremium: `${finalPremium}`,
            AddOnList: additionalService,
          },
        ],
      })
    }
  }, [stage])

  useEffect(() => {
    setPetDetails(pets[index])
    setPetOwnerDetails(auth.user)
    setOpenPetInsuranceModal(true)
  }, [pets, index, auth])

  useEffect(() => {
    if (!pets.length) {
      setModalLoading(true)
      console.log('Getting Pet Details')
      axios
        .post(`${process.env.REACT_APP_INSURANCE_API}/getPets`, {
          petId: petID,
        })
        .then((res) => {
          setPetDetails(res.data.pets)
          setPetOwnerDetails(res.data.user)
          setModalLoading(false)
        })
        .catch((err) => {
          console.log(err)
          rollbar.error(err)
        })
    }
  }, [pets])

  useEffect(() => {
    console.log('Getting Quote ID-1')
    if (petOwnerDetails) {
      console.log('Getting Quote ID-2')
      // $ Getting Quote ID from Pet Owner Details
      axios
        .post(`${process.env.REACT_APP_INSURANCE_API}/getQuote`, {
          proposerName: petOwnerDetails.name,
          MobileNo: petOwnerDetails.mobile,
          EmailID: petOwnerDetails.email,
          AgentCode: '60000272',
          BranchCode: '10',
        })
        .then((res) => {
          if (res.status === 200) {
            setQuoteID(res.data.quoteId)
          }
        })
        .catch((err) => {
          console.log(err)
          rollbar.error(err)
        })
    }
  }, [petOwnerDetails])

  function addPetDetails(quote) {
    let breed = 'large'
    const petBreedSmall = ['kg_0_1', 'kg_1_3', 'kg_3_5', 'kg_5_10']
    const petBreedMedium = ['kg_10_20']
    const petBreedLarge = ['kg_20_40']
    if (petBreedSmall.includes(petDetails.weight)) {
      breed = 'small'
    } else if (petBreedMedium.includes(petDetails.weight)) {
      breed = 'medium'
    } else if (petBreedLarge.includes(petDetails.weight)) {
      breed = 'large'
    } else {
      breed = 'Giant'
    }

    if (quote) {
      // $ Adding Pet Details to Pet Insurance with Quote ID
      axios
        .post(
          `${process.env.REACT_APP_INSURANCE_API}/addPetDetails`,
          {
            NoOfPets: 1,
            quoteId: quote,
            PetDetailsList: [
              {
                SrNo: 1,
                PetName: `${petDetails?.name}`,
                Breed: breed,
                AgeYear: `${Number(petDetails?.age) * 12 + Number(petDetails?.months)}`,
                AgeMonth: `${Number(petDetails?.age) * 12 + Number(petDetails?.months)}`,
                PetSI: `${petSI}`,
              },
            ],
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then((res) => {
          if (res.status === 200) {
            setAmountDetails(res.data)
            setCoverAmountOption([
              {
                label: Math.round(res.data?.SumGrossTotal),
                value: Math.round(res.data?.SumGrossTotal),
              },
            ])
            setTotalCoverAmount(Math.round(res.data?.SumGrossTotal))
            SetInsurance_Premium(Math.round(res.data?.SumGrossTotal))
            //res.data.QuoteRes[0].AddOnListRes find where Type = FDH0001
            const _addOns = res.data.QuoteRes[0].AddOnListRes.filter((item) => item.Type === 'FDH0001')
            setAdditionalService([
              {
                IsSelected: true,
                Premium: _addOns[0].Premium,
                Rate: '8.67',
                Type: 'FDH0001',
              },
            ])
          }
        })
        .catch((err) => {
          console.log(err)
          rollbar.error(err)
        })
    }
  }

  useEffect(() => {
    if (additionalService.length > 0) {
      let total = 0
      additionalService.forEach((item) => {
        total += Number(item.Premium)
      })
      setSumCoverAmountAndAddOns(total + Number(totalCoverAmount))
      setAddOns(total)
      SetInsurance_Premium(Insurance_Premium + total)
    } else {
      setAddOns(0)
    }
  }, [additionalService])

  useEffect(() => {
    setGst(Math.round(sumCoverAmountAndAddOns * 0.18))
    setGatewayCharge(Math.round(sumCoverAmountAndAddOns * 0.02))
    setFinalPremium(
      Math.round(
        sumCoverAmountAndAddOns + sumCoverAmountAndAddOns * 0.18,
        // +(sumCoverAmountAndAddOns + sumCoverAmountAndAddOns * 0.18) * 0.02,
      ),
    )
  }, [Insurance_Premium, AddOns])

  // $ Functions Handling Back Button task
  const handleBackButton = () => {
    {
      stage > 1 ? setStage(stage - 1) : navigate(-1)
    }
    if (stage > 1) {
      setDisclaimer({
        ...disclaimer,
        first: false,
        second: false,
      })
    }
  }
  //$ Function Handling input of Parent Details
  const handleInput = (e) => {
    let variable = e.target.name
    let value = e.target.value
    setParentData({ ...parentData, [variable]: value })
  }
  //$ Function Handling input of Pet Details
  const handlePetInput = (e) => {
    let variable = e.target.name
    let value = e.target.value

    setFormData({ ...formData, [variable]: value })
  }

  //$ Function handling Pan Card validation
  //` Receive pan card number as props and validate its format is AAAAA1234Z
  const validatePAN = (panNumber) => {
    var regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/
    if (regex.test(panNumber)) {
      return true
    }
    return false
  }

  // $ Function handling next button Task
  // ` 1. Validating the form data
  // ` 2. Setting the stage
  const handleNextButton = () => {
    if (stage === 1) {
      window.scrollTo(0, 0)
      setStage(stage + 1)
    }
    if (stage === 4) {
      return
    }
    if (stage === 2) {
      const requiredFormData = ['DistinguishingFeature', 'IdentificationMark', 'image1', 'image2', 'image3', 'image4']
      for (let i = 0; i < requiredFormData.length; i++) {
        if (formData[requiredFormData[i]] === '') {
          if (requiredFormData[i] === 'image1' || requiredFormData[i] === 'image2' || requiredFormData[i] === 'image3' || requiredFormData[i] === 'image4') {
            toast.error('Please upload all the images')
            return
          }
          toast.error(`Please ${requiredFormData[i]} fields`)
          return
        }
      }
      if (formData.vaccinationsProvided) {
        let vaccinationProvided = false
        for (const key in formData.vaccinationsProvided) {
          if (formData.vaccinationsProvided[key]) {
            vaccinationProvided = true
            break
          }
        }
        if (!vaccinationProvided) {
          toast.error('Please select at least one vaccination')
          return
        }
      }
      if (formData.healthy === 'No') {
        toast.error('Dog Should Sound healthy')
        return
      }
      if (formData.FreeFromVice === 'No') {
        toast.error('Dog House should Sound healthy')
        return
      }
      setStage(stage + 1)
      window.scrollTo(0, 0)
    }

    if (stage === 3) {
      const notEmpty = [
        'parentName',
        'MobileNo',
        'EmailId',
        'Gender',
        'Address1',
        'City',
        'PinCode',
        'PanCardNumber',
        'Occupation',
        'MaritalStatus',
        'DOB',
      ]

      for (let i = 0; i < notEmpty.length; i++) {
        if (parentData[notEmpty[i]] === '') {
          toast.error(`Please ${notEmpty[i]} fields`)
          return
        }
      }
      if(parentData.PanCardPhoto === ''){
        toast.error(`Please upload PAN card photo`)
        return
      }

      //` check pan card number is valid or not
      if (!validatePAN(parentData.PanCardNumber)) {
        toast.error('Please enter valid PAN card number  AAAAP1234A')
        return
      }

      setStage(stage + 1)
      window.scrollTo(0, 0)
    }
  }

  return (
    <Container>
      <InsuranceModelAndPetSi
        dogInsuranceVisibility={openPetInsuranceModal}
        petData={petDetails}
        setPetSI={setPetSI}
        setFormData={setFormData}
        petSI={petSI}
        onPressConfirm={() => setNextBtn(!nextBtn)}
        onPressBack={() => {
          window.history.back()
        }}
        onPressNext={() => {
          if (!nextBtn) {
            setOpenPetInsuranceModal(false)
            addPetDetails(quoteID)
          }
        }}
        nextBtn={nextBtn}
      />
      <LoadingModel modalVisibility={modalLoading} />
      <div className="py-4">
        <TopButtonsInsurance stage={stage} />

        {/* Show pet selector Here */}

        {(stage === 1 || stage === 4) && (
          <>
            {stage === 1 && (
              <>
                <PetProfileCard data={petDetails} className="mt-4" noEdit />
                <h4 className="text-lg mt-5">
                  <b>Base Plan + Add Ons</b>
                </h4>
              </>
            )}

            <div className="lg:flex mt-8">
              <div className="lg:w-[60%] mb-4">
                {stage === 1 ? (
                  <div className="border-1 border-[#AAAAAA] rounded-md py-[14.83px] px-[20.40px]">
                    <p>Sum Insured</p>
                    <div className="flex flex-wrap justify-between">
                      <p className="bg-lightYellow mt-1 p-2 rounded w-[300px] lg:text-sm mb:text-[12px]">The total cover amount provided to your dog</p>
                      {/* put the price for Insurance in Constants.js file */}
                      <select
                        className="border-1 border-[#ABB4BD] px-4 hidden"
                        name="languages"
                        id="lang"
                        disabled={true}
                        onChange={(e) => {
                          setTotalCoverAmount(e.target.value)
                        }}
                      >
                        {coverAmountOption.map((item, index) => {
                          return (
                            <option key={index} value={item.value}>
                              {item.label}
                            </option>
                          )
                        })}
                      </select>
                      <span className="flex font-semibold justify-between mt-4">
                        <p>Rs. {totalCoverAmount}</p>
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="border-[4.17px] border-darkGreen lg:w-[346.06px] mb:w-full h-[150.1px] rounded-md">
                    <img alt="dog_image" src={INSURANCE_OPTIONS[0].image} className="w-[244.61px] h-[91.73px] my-[29.19px] mx-[50.73px]" />
                  </div>
                )}

                <div className="border-1 border-gray-400 rounded-md p-4 mt-5">
                  <h4 className="font-bold">Additional Add-ons you can choose from:</h4>
                  <div className="mt-4">
                    {amountDetails?.QuoteRes[0]?.AddOnListRes.map((item) => (
                      <div key={item.Type} className="mt-2">
                        <label className="flex justify-between">
                          <span className="flex">
                            <input
                              type="checkbox"
                              className="checkboxStyle mr-2"
                              name={item.Type}
                              // defaultChecked
                              checked={additionalService.findIndex((i) => i.Type === item.Type) !== -1 ? true : false}
                              onChangeCapture={() => {
                                if (stage === 1 && item.Type !== 'FDH0001') {
                                  const index = additionalService.findIndex((x) => x.Type === item.Type)
                                  if (index === -1) {
                                    setAdditionalService([
                                      ...additionalService,
                                      {
                                        ...item,
                                        IsSelected: true,
                                      },
                                    ])
                                  } else {
                                    additionalService.splice(index, 1)
                                    setAdditionalService([...additionalService])
                                  }
                                }
                              }}
                            />
                            <p>{petInsuranceAdddOns[item.Type]}</p>
                          </span>
                          <p className="font-semibold ml-2">Rs.{Math.round(item.Premium)}</p>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="border-1 border-gray-400 border-t-0 rounded-md lg:w-[38%] ml-0 lg:ml-5 pt-0">
                <p className="bg-[#f49f02] p-2 rounded-md text-sm mt-0 text-center">Your Quote No. {quoteID}</p>
                <div className="m-4">
                  <span className="flex font-semibold justify-between">
                    <p>Plan Details</p>
                    <p>Amount</p>
                  </span>
                  <span className="flex justify-between">
                    <p>
                      {petDetails?.name} , {petDetails?.breed}
                    </p>
                    <p>Rs. {totalCoverAmount}</p>
                  </span>
                  {/* { (additionalService.Additional1 === 'Yes' || additionalService.Additional2 === 'Yes' || additionalService.Additional3 === 'Yes' || additionalService.Additional4 === 'Yes') && */}
                  <span className="flex mt-4 font-semibold justify-between">
                    <p>Add-Ons</p>
                    <p>Rs. {AddOns}</p>
                  </span>
                  {/* } */}
                  <span className="flex mt-4 font-semibold justify-between">
                    <p>Total Premium</p>
                    <p>Rs. {sumCoverAmountAndAddOns}</p>
                  </span>
                  <span className="flex font-semibold justify-between">
                    <p>Premium without Service Tax</p>
                    <p>Rs. {sumCoverAmountAndAddOns}</p>
                  </span>
                  <hr className="my-4" />
                  <span className="flex font-semibold justify-between">
                    <p>Gross Total</p>
                    <p>Rs.{sumCoverAmountAndAddOns}</p>
                  </span>
                  <span className="flex justify-between">
                    <p>GST @ 18%</p>
                    <p>Rs. {Math.round(sumCoverAmountAndAddOns * 0.18)}</p>
                  </span>
                  <span className="flex justify-between">
                    <p>Payment Gateway fees 2%</p>
                    <p>
                      Rs. 0
                      {/* {Math.round(
                        (sumCoverAmountAndAddOns +
                          Math.round(sumCoverAmountAndAddOns * 0.18)) *
                          0.02,
                      )} */}
                    </p>
                  </span>
                  <hr className="my-4" />
                  <span className="flex font-semibold justify-between">
                    <p>Final Premium</p>
                    <p>Rs. {finalPremium}</p>
                  </span>
                  <p className="bg-lightYellow mt-5 p-2 rounded text-sm text-center">The total amount you pay (incl. GST) while buying the policy</p>
                </div>
              </div>
            </div>
          </>
        )}

        {stage === 2 && <Stage2 formData={formData} handlePetInput={handlePetInput} setFormData={setFormData} petDetails={petDetails} />}

        {stage === 3 && <Stage3 parentData={parentData} handleInput={handleInput} setParentData={setParentData} />}

        {stage === 4 && (
          <Stage4
            formData={formData}
            parentData={parentData}
            petDetails={petDetails}
            vaccinationsProvidedList={formData.vaccinationsProvided}
            setDisclaimer={setDisclaimer}
            disclaimer={disclaimer}
          />
        )}

        <hr className="w-[80%] mt-[52.92px] ml-[10%] my-4" />

        <div className="flex justify-center my-16">
          <div>{!loading && <GradientButton title="BACK" onPress={handleBackButton} className={'mr-2 bg-blue-300 text-blue-500'} />}</div>
          {stage <= 3 ? (
            <GradientButton
              title="NEXT"
              onPress={() => {
                if (!loading) {
                  handleNextButton()
                } else {
                  return
                }
              }}
              disabled={loading}
              className={'ml-2'}
            />
          ) : (
            <div className={`h-[50px] mt-[-28px] ml-2  ${disclaimer.first === true && disclaimer.second === true ? 'block' : 'hidden'}`}>
              <PayWithRazorPay
                amount={finalPremium}
                email={parentData.EmailId}
                firstname={parentData.parentName}
                phone={parentData.MobileNo}
                dataToBeSent={dataToBeSent}
                dataForFG={dataForFG}
                setDataForFG={setDataForFG}
                loading={loading}
                setLoading={setLoading}
                ckycDetails={ckycNumber}
                proposalId={proposalId}
              />
            </div>
          )}
        </div>
      </div>
    </Container>
  )
}

export default PetInsurance
