import { PETS } from '../types';

const initialState = {
  pets: [],
  loading: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case PETS.ADD_ALL_PET:
      return{...state, pets:payload}
    case PETS.ADD_PET:
      return { ...state, pets: [payload, ...state.pets] };
    case PETS.EDIT_PET: {
      return {
        ...state,
        pets: state.pets.map((item) => {
          if (item.docid === payload.docid) return { ...item, ...payload };
          return item;
        }),
      };
    }
    case PETS.FETCH_PET:
      return { ...state, pets: payload, loading: false };
    default:
      return state;
  }
};
