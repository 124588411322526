import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children, to }) => {
  const { user, loading } = useSelector(({ auth }) => auth);
  const { email, displayName } = user || {};

  if (loading) {
    return <h1>loading</h1>;
  }

  // if (!user) {
  //   return <Navigate to='/login' />;
  // }

  // if (
  //   (!email || !displayName) &&
  //   to !== '/add-user-details' &&
  //   to !== '/onboarding'
  // ) {
  //   return <Navigate to='/add-user-details' />;
  // }

  return children;
};

PrivateRoute.propTypes = {
  children: PropTypes.object.isRequired,
  to: PropTypes.string,
};

export default PrivateRoute;
