import React from "react";
import PropTypes from "prop-types";

const InsuranceCard = ({ key, title, plane }) => {
  return (
    <div
      key={key}
      className="border-1 border-t-0 border-gray-500 mt-6 rounded-md  w-full lg:min-w-[48%] lg:max-w-[48%]"
    >
      <p
        className={`text-center text-[25px] h-[64px] pt-3 text-white ${
          title === "Add-On Coverage" ? "bg-darkGreen" : "bg-yellow"
        }`}
      >
        {title}
      </p>
      <ul>
        <li className="flex mt-6 mx-2 font-sans text-[20px]">
          <p className="bg-darkGreen mr-1 mt-2 w-[9px] h-[9px] rounded-full"></p>
          {plane[0]}
        </li>

        <li className="flex mt-6 mx-2 font-sans text-[20px]">
          <p className="bg-darkGreen mr-1 mt-2 w-[9px] h-[9px] rounded-full"></p>
          {plane[1]}
        </li>

        <li className="flex mt-6 mx-2 font-sans text-[20px]">
          <p className="bg-darkGreen mr-1 mt-2 min-w-[9px] h-[9px] rounded-full"></p>
          {plane[2]}
        </li>

        <li className="flex mt-6 mb-4 mx-2 font-sans text-[20px]">
          <p className="bg-darkGreen mr-1 mt-2 min-w-[9px] h-[9px] rounded-full"></p>
          {plane[3]}
        </li>
      </ul>
    </div>
  );
};

InsuranceCard.propTypes = {
  key: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  plane: PropTypes.array.isRequired,
};

export default InsuranceCard;
