import React from 'react'

// eslint-disable-next-line react/prop-types
const SecondaryModal = ({ children, visibilty }) => {
  return (
    <>
      {visibilty && (
        <div className="bg-modalColour justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            <div className="border-0 rounded-lg  relative flex flex-col w-full  outline-none focus:outline-none">
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SecondaryModal
