import React from 'react'
import { Container, Logo } from '../Components'
import Youtube from '../Assets/youtube.png'
import Facebook from '../Assets/facebook.png'
import Instagram from '../Assets/instagram.png'
import { MdOutlinePhone, MdOutlineEmail, MdLocationPin } from 'react-icons/md'
import { VscTwitter } from 'react-icons/vsc'
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa'
import { AiOutlineRight } from 'react-icons/ai'

const QUICK_LINKS = [
  { title: 'Home', link: 'https://abhivridhi.com/index.php' },
  { title: 'About Us', link: 'https://abhivridhi.com/aboutus.php' },
  { title: 'Solution', link: 'https://abhivridhi.com/index.php#services' },
  {
    title: 'Term of service',
    link: 'https://abhivridhi.com/#',
  },
  { title: 'Privacy Policy', link: 'https://abhivridhi.com/#' },
]
const Our_Solutions = [
  { title: 'PROPERTY INSURANCE', link: 'https://abhivridhi.com/index.php' },
  { title: 'LIABILITY INSURANCE', link: 'https://abhivridhi.com/aboutus.php' },
  {
    title: 'EMPLOYEE BENEFITS',
    link: 'https://abhivridhi.com/index.php#services',
  },
  {
    title: 'HOME INSURANCE',
    link: 'https://abhivridhi.com/#',
  },
  { title: 'MOTOR INSURANCE', link: 'https://abhivridhi.com/#' },
  { title: 'HEALTH INSURANCE', link: 'https://abhivridhi.com/#' },
  { title: 'TRAVEL INSURANCE', link: 'https://abhivridhi.com/#' },
]

const CONNECT_OPTIONS = [
  {
    title: '6B, 161A, Clan House, near Acres Club, Chembur 400071',
    id: '1',
    Icon: MdLocationPin,
  },
  {
    title: '+91 8169145227',
    id: '2',
    Icon: MdOutlinePhone,
  },
  {
    title: 'petparentsupport@pawpurrfect.co',
    id: '3',
    Icon: MdOutlineEmail,
  },
]

const Footer = () => {
  return (
    <div className="font-roboto">
      <Container
        className="bg-[#f4f4f4] py-4"
        wrapperClassName="flex flex-col md:flex-row px-4 md:px-0"
      >
        <div className="flex-1">
          <a href="https://abhivridhi.com/" target="_blank" rel="noreferrer">
            <Logo size="md" />
          </a>
          <p className=" mt-3 text-[16px] text-[#b53427] font-bold">
            Abhivridhi Insurance Brokers Pvt. Ltd.
          </p>
          <p className=" mt-3 text-[14px] ">
            No.2, Ambika Apartment, Pandurang Colony, Erandwane,
            <br />
            Pune 411004
            <br />
            IRDAI Broker License No. 461 License Category -<br />
            Direct General Valid till 05-09-2022
            <br />
            Email: info@abhivridhi.com
            <br />
            Phone: 020 25420906
            <br />
          </p>
        </div>
        <div className="flex-1">
          <h4 className="text-[#b53427] font-bold  text-[20px] mb-1 md:mb-6 mt-4 md:ml-16">
            Site Map
          </h4>
          {QUICK_LINKS.map((item) => (
            <div className="my-1 md:ml-16 cursor-pointer text-[14px] mt-2  flex flex-row items-center">
              <AiOutlineRight className="mr-2" />
              <p
                className='hover:text-[#b53427]'
                key={item.title}
              >
                <a href={item.link} target="_blank" rel="noreferrer">
                  {item.title}
                </a>
              </p>
            </div>
          ))}
        </div>
        <div className="flex-1">
          <h4 className="text-[#b53427] font-bold  text-[20px] mb-1 md:mb-6 mt-4 md:ml-16">
            Our Solutions
          </h4>
          {Our_Solutions.map((item) => (
            <div
              className="my-1 md:ml-16 cursor-pointer text-[14px] mt-2 flex flex-row items-center"
              key={item.title}
            >
              <AiOutlineRight className="mr-2" />
              <p className="hover:text-[#b53427]">
                <a href={item.link} target="_blank" rel="noreferrer">
                  {item.title}
                </a>
              </p>
            </div>
          ))}
        </div>
        <div className="flex-1">
          <h4 className="text-[#b53427] font-bold  text-[20px] mb-1 md:mb-6 mt-4">
            Our Social Networks
          </h4>
          <h4 className="text-[14px] mb-1 md:mb-6 mt-4">
            Socially Yours!
            <br />
            Like Share Comment | Spread the <br /> word <br />
          </h4>
          {/* {CONNECT_OPTIONS.map(({ id, Icon, title }) => (
            <div key={id} className="flex items-center mt-2">
              <Icon size={24} className="mr-3" />
              <p className="my-1 text-[16px]">{title}</p>
            </div>
          ))} */}

          <div className="flex mt-6">
            <a
              href="https://twitter.com/abhivridhi_in"
              target="_blank"
              rel="noopener noreferrer"
              className="p-2 rounded-full bg-[#b53427] mr-2"
            >
              <VscTwitter size={24} color="white" />
            </a>
            <a
              href="https://www.facebook.com/Abhivridhiinsurance/"
              target="_blank"
              rel="noopener noreferrer"
              className="p-2 rounded-full bg-[#b53427] mr-2"
            >
              <FaFacebookF size={24} color="white" />
            </a>
            <a
              href="https://www.linkedin.com/company/abhivridhi-insurance-brokers/about/"
              target="_blank"
              rel="noopener noreferrer"
              className="p-2 rounded-full bg-[#b53427] mr-2"
            >
              <FaLinkedinIn size={24} color="white" />
            </a>
          </div>
        </div>
      </Container>
      <div className="bg-[#b53427] py-4 flex flex-row  justify-around p-2">
        <p className=" text-white  mx-2 md:mx-0 text-[16px]">
          © Copyright Abhiviridhi. All Rights Reserved
        </p>
        <span className="text-white  mx-2 md:mx-0 text-[16px]">
          Designed by Kalpataru
        </span>
      </div>
      {/* <Container
        className="bg-[#79d1bb] py-4"
        wrapperClassName="flex flex-row justify-between relative px-2 md:px-0"
      >
        <div className="flex flex-row justify-between w-full">
          <h6 className="max-w-sm text-dark text-base">
            All Rights Reserved. Copyright 2021. Shodashi Sutras Private Limited
          </h6>
          <div className="flex flex-col md:flex-row">
            <a href="https://apps.apple.com/us/app/pawpurrfect/id1592071281">
              <img src={AppStore} className="cursor-pointer" alt="app-store" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.pawpurrfectseekers">
              <img
                src={GooglePlay}
                className="cursor-pointer md:mr-4 mb-1 md:mb-0"
                alt="google-play"
              />
            </a>
          </div>
          <a href="https://wa.me/8169145227?text=urlencodedtext ">
            <img
              src={Whatsapp}
              className=" cursor-pointer hidden md:flex w-12 h-12 shadow"
              alt="whatsapp"
            />
          </a>
        </div>
      </Container> */}
    </div>
  )
}

export default Footer
