import PropTypes from 'prop-types';
import React from 'react';
// import OtpInput from 'react-otp-input';
import { GradientButton, Modal } from '..';
import Tick from '../../Assets/Tick.jpg';

const OTPModal = ({ modalVisibility, setModalVisibility }) => {
  return (
    <Modal visibilty={modalVisibility}>
      <div className='relative py-10 px-10 rounded'>
        <div className='flex justify-center'>
          <img src={Tick} alt='sad' srcSet='' />
        </div>
        <div className='my-4'>
          <h1 className='text-dark text-md font-semibold leading-relaxed text-center'>
            Please enter the 6 digit OTP provided by the Expert to mark the job
            as complete
          </h1>
        </div>
        <div className='flex justify-center'>
          {/* <OtpInput
            //   value={otp}
            shouldAutoFocus
            //   onChange={(otp) => setFormData({ ...formData, otp })}
            numInputs={6}
            placeholder='------'
            inputStyle='font-medium font-number border-1 text-lg text-base rounded-md mx-4 otp-input first:ml-0'
            containerStyle='my-4'
          /> */}
        </div>
        <h3 className='col-span-2 mt-3 text-sm text-center'>
          Didn’t receive the OTP?{' '}
          <span className='text-darkGreen'>RESEND OTP</span>
        </h3>
        <div className='flex justify-center mt-4 gap-3'>
          <GradientButton
            title='VERIFY'
            onPress={() => setModalVisibility(false)}
          />
          <GradientButton
            title='CANCEL'
            grey
            onPress={() => setModalVisibility(false)}
          />
        </div>
      </div>
    </Modal>
  );
};

OTPModal.defaultProps = {
  modalVisibility: false,
  setModalVisibility: () => {},
};

OTPModal.propTypes = {
  modalVisibility: PropTypes.bool,
  setModalVisibility: PropTypes.func,
};

export default OTPModal;
