import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useRollbar } from '@rollbar/react'

import axios from 'axios'
// import Carousel from 'carousel-react-rcdev'
import { Carousel, ChipCard, Container, GradientButton, PetCard, InsuranceCard } from '../Components'
import { InvalidInsuranceModel, InvalidAgeInsuranceModel, InsuranceModel, LoadingModel, CheckCKYC, CreateCKYC } from '../Modal'

import { ServiceOptions, SERVICES_TEXT, SERVICE_OPTIONS, INSURANCE_OPTIONS } from '../Constants/Constants'

import { setUser } from '../Redux/actions/authActions'
import { addAllPets } from '../Redux/actions/petActions'
import { toast } from 'react-toastify'

const initialState = {
  selectedPet: '',
  selectedService: '',
  date: '',
  endDate: '',
  serviceDatas: [],
  userRequirements: {},
  optionalRequirements: '',
  visitType: 'Home Visit',
  walkDuration: '',
  disabledDays: [],
}

export let selectedPetForInsurance
export let selectedDateForPathology
export let selectedTimeForPathology
export let selectedPackage

const CreateRequestPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const rollbar = useRollbar()
  const { id } = useParams()
  const location = useLocation()

  // console.log('id', id)
  const {
    pet: { pets },
  } = useSelector(({ pet }) => ({ pet }))

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_INSURANCE_API}/verifyId`, {
        id: `${id}`,
      })
      .then((res) => {
        // console.log('res.user', res.data.user)
        dispatch(setUser(res.data.user))
        dispatch(addAllPets(res.data.pets))
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        rollbar.error(e)
      })
  }, [id])

  const [formData, setFormData] = useState({
    ...initialState,
    selectedService: location?.state?.type || '',
  })
  const [insuranceData, setInsuranceData] = useState({
    insuranceCompany: '',
    insuranceCompanyImage: '',
  })

  const { selectedPet, selectedService } = formData

  const [invalidInsuranceVisibility, setInvalidInsuranceVisibility] = useState(false)
  const [loading, setLoading] = useState(true)
  const [invalidAgeInsuranceVisibility, setInvalidAgeInsuranceVisibility] = useState(false)
  const [dogInsuranceVisibility, setDogInsuranceVisibility] = useState(false)
  const [pet, setPet] = useState('')

  const [nextBtn, setNextBtn] = useState(true)
  useEffect(() => {
    const petDet = pets.find((item) => {
      return item.id === formData.selectedPet
    })
    setPet(petDet)
  }, [formData.selectedPet])
  const [checkCKYC, setCheckCKYC] = useState(false)
  const [createCKYC, setCreateCKYC] = useState(false)
  const [ckycData, setCkycData] = useState({
    fullName: '',
    idType: '',
    idNumber: '',
    DOB: '',
    gender: '',
  })
  const [ckycDetails, setCkycDetails] = useState()
  const [fetchLoading, setFetchLoading] = useState(false)
  const onClickNext = () => {
    setFetchLoading(true)
    const date = new Date(ckycData.DOB)
    const dd = String(date.getDate()).padStart(2, '0')
    const mm = String(date.getMonth() + 1).padStart(2, '0')
    const yyyy = date.getFullYear()
    const newDate = dd + '-' + mm + '-' + yyyy
    console.log('dob sended', newDate)

    // toast if any field is empty
    ckycData.fullName === '' && toast.error('Please enter your full name')
    ckycData.idType === '' && toast.error('Please select your ID type')
    ckycData.idNumber === '' && toast.error('Please enter your ID number')
    ckycData.DOB === '' && toast.error('Please enter your date of birth')
    ckycData.gender === '' && toast.error('Please Select your gender')

    axios
      .post(process.env.REACT_APP_VERIFY_CYCK_URL, {
        req_id: '',
        proposal_no: '',
        customer_type: 'I',
        id_type: ckycData.idType,
        id_num: ckycData.idNumber,
        dob: newDate,
        full_name: ckycData.fullName,
        gender: ckycData.gender,
        url_type: '',
        system_name: 'Webagg',
      })
      .then((res) => {
        console.log(res.data.result)
        setCkycDetails(res.data)
        if (res.data?.result?.ckyc_number) {
          console.log('CKYC Number Found')
          setFetchLoading(false)
          setCheckCKYC(false)
          // setDogInsuranceVisibility(true)
          navigate(
            `/pet-insurance/${selectedPet}`,

            { state: { ckycDetails: res.data } },
          )
        } else {
          console.log('CKYC Number Not Found')
          setFetchLoading(false)
          setCheckCKYC(false)
          setCreateCKYC(true)
        }
      })
      .catch((e) => {
        setFetchLoading(false)
        console.log(e)
        rollbar.error(`Error in CreateCKYC FE api with Proposal Id `)
        toast.error('Something went wrong')
      })
  }
  const onClickCreateRequest = () => {
    toast.info('Please wait while we redirect you to KYC')
    if (ckycDetails?.url) {
      console.log('url found')
      console.log(ckycDetails.url)
      const form = document.createElement('form')
      form.method = 'POST'
      form.action = ckycDetails.url
      form.style.display = 'none'

      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = 'Aggregator_KYC_Req_No'
      input.value = ckycDetails.proposal_id
      form.appendChild(input)

      const input2 = document.createElement('input')
      input2.type = 'hidden'
      input2.name = 'IC_KYC_No'
      input2.value = ckycDetails.proposal_id
      form.appendChild(input2)

      const input3 = document.createElement('input')
      input3.type = 'hidden'
      input3.name = 'Aggregator_Return_URL'
      // http://127.0.0.1:5001/pawpurrfect-stage/us-central1/redirectCKYC
      // pass policy id and selected pet id  as query param

      input3.value = `${process.env.REACT_APP_CKYC_REDIRECT_URL}?pet=${selectedPet}&policyId=${ckycDetails.proposal_id}`
      form.appendChild(input3)

      document.body.appendChild(form)
      // check if form is valid
      console.log(form.checkValidity())
      console.log(form)
      setTimeout(() => {
        form.submit()
      }, 2000)
    } else {
      console.log('url not found')
      axios
        .post(process.env.REACT_APP_VERIFY_CYCK_URL, {
          req_id: '',
          proposal_no: '',
          customer_type: 'I',
          id_type: 'PAN',
          id_num: 'AABCA1234A',
          dob: '01-01-1990',
          full_name: 'TEST',
          gender: 'M',
          url_type: '',
          system_name: 'Webagg',
        })
        .then((res) => {
          console.log(res.data)
          const form = document.createElement('form')
          form.method = 'POST'
          form.action = res.data.url
          form.style.display = 'none'

          const input = document.createElement('input')
          input.type = 'hidden'
          input.name = 'Aggregator_KYC_Req_No'
          input.value = res.data.proposal_id
          form.appendChild(input)

          const input2 = document.createElement('input')
          input2.type = 'hidden'
          input2.name = 'IC_KYC_No'
          input2.value =res.data.proposal_id
          form.appendChild(input2)

          const input3 = document.createElement('input')
          input3.type = 'hidden'
          input3.name = 'Aggregator_Return_URL'
          input3.value = `${process.env.REACT_APP_CKYC_REDIRECT_URL}?pet=${selectedPet}&policyId=${res.data.proposal_id}`
          form.appendChild(input3)

          document.body.appendChild(form)
          // check if form is valid
          console.log(form.checkValidity())
          console.log(form)
          setTimeout(() => {
            form.submit()
          }, 2000)
        })
        .catch((e) => {
          setFetchLoading(false)
          console.log(e)
          rollbar.error(`Error in CreateCKYC FE api with Proposal Id `)
          toast.error('Something went wrong')
        })
    }
  }
  const onCLickBack = () => {
    setCheckCKYC(true)
    setCreateCKYC(false)
  }

  return (
    <Container className="py-4 md:py-8">
      {/* //TODO: Check in ui from were it opens */}
      <CheckCKYC
        checkCKYC={checkCKYC}
        setCheckCKYC={setCheckCKYC}
        formData={ckycData}
        setFormData={setCkycData}
        onClickNext={onClickNext}
        onClickCreateRequest={onClickCreateRequest}
        loading={fetchLoading}
      />
      <CreateCKYC
        CreateCKYCModal={createCKYC}
        setCKYCModal={setCreateCKYC}
        formData={ckycData}
        onCLickBack={onCLickBack}
        onClickCreateRequest={onClickCreateRequest}
      />
      {/*
           - Invalid Insurance Model
           - Open when pet insurance is not available or we don't provide insurance for that pet
      */}
      <InvalidInsuranceModel invalidInsuranceVisibility={invalidInsuranceVisibility} onPressBack={() => setInvalidInsuranceVisibility(false)} />
      <LoadingModel modalVisibility={loading} />
      {/*
           - Invalid Age Insurance Model
           - Open when pet age is not in range of insurance
      */}
      <InvalidAgeInsuranceModel invalidInsuranceVisibility={invalidAgeInsuranceVisibility} onPressBack={() => setInvalidAgeInsuranceVisibility(false)} />
      {/*
            - Insurance Confirmation Model
    */}
      <InsuranceModel
        dogInsuranceVisibility={dogInsuranceVisibility}
        petData={pet}
        onPressConfirm={() => setNextBtn(!nextBtn)}
        onPressBack={() => (setNextBtn(true), setDogInsuranceVisibility(false))}
        onPressNext={() =>
          !nextBtn &&
          navigate(
            `/pet-insurance/${selectedPet}`,

            { state: { ckycDetails: ckycDetails } },
          )
        }
        nextBtn={nextBtn}
      />
      <div className="md:flex sm:grid sm:grid-cols-1 justify-between md:pb-3">
        <h4 className="text-lg text-center">
          <b>Create Request</b>
        </h4>
        {/* <div className="flex justify-center mt-2 md:mt-0">
          <GradientButton
            onPress={() => setModalVisibility(true)}
            title="CANCEL REQUEST"
          />
        </div> */}
      </div>
      <div className="grid gap-5 gap-y-3">
        <div className="grid md:grid-cols-2 gap-5">
          <div>
            <div className="flex justify-between">
              <h6 className="text-center md:text-left mt-2 md:mt-0 text-justify lg:w-[400px] w-[200px]">Select your pet for whom you want insurance</h6>

              {/* <AddPetCard /> */}
            </div>

            {selectedService === SERVICE_OPTIONS.INSURANCE && (
              <h3 className="bg-lightYellow mt-[13.16px] rounded-md pt-[8.61px] font-roboto text-center text-[13.09px] font-normal h-[36.49px] lg:w-[350px] mb:w-full">
                Currently insurance partners only offer policies for dogs
              </h3>
            )}

            <div className="grid grid-cols-2 mt-4 gap-3">
              {pets.length < 3 ? (
                pets.map((item) => (
                  <div
                    onClick={() => {
                      setFormData({ ...formData, selectedPet: item.docid })
                      setInsuranceData({
                        insuranceCompany: '',
                        insuranceCompanyImage: '',
                      })
                    }}
                    key={item.id}
                  >
                    <PetCard item={item} selected={selectedPet === item.docid} className="mb-12 " />
                  </div>
                ))
              ) : (
                <Carousel
                  className="col-span-3"
                  items={pets}
                  numColumns={2}
                  onItemClick={(data) => (
                    setFormData({ ...formData, selectedPet: data.docid }),
                    setInsuranceData({
                      insuranceCompany: '',
                      insuranceCompanyImage: '',
                    })
                  )}
                  renderChild={(item) => <PetCard item={item} key={item.id} selected={selectedPet === item.docid} className="mb-12 " />}
                />
              )}
            </div>

            <div className="hidden">{(selectedPetForInsurance = formData.selectedPet)}</div>
          </div>

          <div>
            <h6>Select a service</h6>
            <div className="p-2">
              {ServiceOptions.map(
                ({ image, title, value, isDisabled }) =>
                  title == 'Pet Insurance' && (
                    <ChipCard
                      title={title}
                      disabled={isDisabled}
                      isPetSelected={(selectedPet == '' ? false : true) && !isDisabled}
                      key={title}
                      isSelected={value === formData.selectedService}
                      fill={false}
                      Icon={image}
                      onPress={() =>
                        setFormData({
                          ...formData,
                          selectedService: value,
                          serviceDatas: [],
                          date: '',
                          endDate: '',
                        })
                      }
                    />
                  ),
              )}
            </div>
          </div>
        </div>
        <div className="grid md:grid-cols-2 gap-5">
          <div>
            {/* - If Pet Insurance is selected then displaying the Insurance Companies */}

            <div>
              <h6 className="font-roboto text-[21.79px] font-normal">Select your preferred Insurance company</h6>
              <div className="bg-lightYellow mt-3 p-2 rounded w-50 text-sm lg:w-[235.35px] mb:w-full">Terms & Conditions Applicable</div>
              {INSURANCE_OPTIONS.map((item) => (
                <div
                  key={item.id}
                  className={`
                  hover:cursor-pointer 
                  mt-6 mr-6 p-4 mb-6 border-1 w-[40%] border-gray-500 rounded-md ${insuranceData.insuranceCompany !== '' && 'border-2 border-[#32b6a5]'}`}
                  onClick={() => {
                    pet.type == 'Dog' || pet.type == 'dog'
                      ? setInsuranceData({
                          ...insuranceData,
                          insuranceCompany: `${item.title}`,
                          insuranceCompanyImage: `${item.image}`,
                        })
                      : setInvalidInsuranceVisibility(true)
                  }}
                >
                  <img className={`content-center`} src={item.image} />
                </div>
              ))}
            </div>
          </div>
          {
            // - Displaying the Pet Insurance Companies
            // - If the Pet Insurance is selected
          }
          {insuranceData.insuranceCompany !== '' && selectedService === SERVICE_OPTIONS.INSURANCE && (
            <div className="border-1 border-[#32b6a5] rounded-md p-4">
              <img src={insuranceData.insuranceCompanyImage} className="w-[34%] ml-[33%]" />
              <div className="flex flex-col lg:flex-row justify-between">
                {INSURANCE_OPTIONS.map((item) =>
                  item.typeOfInsurance.map((plane, index) => <InsuranceCard key={index} title={plane.title} plane={plane.plane} />),
                )}
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-center">
          <GradientButton
            title="BACK"
            onPress={() => {
              navigate('/')
            }}
            className={'mr-2 bg-blue-300 text-blue-500'}
          />
          <GradientButton
            title="NEXT"
            onPress={() => {
              if (selectedService == SERVICE_OPTIONS.INSURANCE && (pet.type == 'dog' || pet.type == 'Dog')) {
                let age = Number(pet.age) * 12 + Number(pet.months)
                if (age > 6) {
                  setCheckCKYC(true)
                  // navigate(`/pet-insurance/${selectedPet}`)
                } else {
                  setInvalidAgeInsuranceVisibility(true)
                }
              } else {
                setInvalidInsuranceVisibility(true)
              }
            }}
            className={'ml-2'}
          />
        </div>
      </div>
    </Container>
  )
}

export default CreateRequestPage
