import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import groom from "../../Assets/icons/groom.png";
import star from "../../Assets/icons/star.png";
import { SERVICES_TEXT, SERVICE_STATUS } from "../../Constants/Constants";
import {
  activeServiceIdAction,
  cancelServiceRequest,
} from "../../Redux/actions/serviceActions";
import { getOfferStatus } from "../../utils/utils";
import LazyImage from "../LazyImage";

// const bool = true;

const ReqPetCard = ({ data, otherDatas }) => {
  const {
    pet_obj: { photo, name, breed, age, months, image },
    booked_expert,
    // offer_acceptance_status,
    request_open_for_offers,
    size,
    request_datetime,
    docid,
    offer_received_count,
    rating_by_seeker,
    report,
  } = data;
  let humanDate = request_datetime?.seconds
    ? new Date(request_datetime.seconds * 1000)
    : request_datetime;
  const [loading, setLoading] = useState(false);
  const offerStatus = getOfferStatus(request_open_for_offers);
  const dispatch = useDispatch();

  const handleDeletePress = (e) => {
    setLoading(true);
    e.preventDefault();
    e.stopPropagation();
    dispatch(
      cancelServiceRequest(docid, () => {
        setLoading(false);
      })
    );
  };

  const handleReviewModal = (e) => {
    e.preventDefault();
    if (otherDatas.setModalVisibility) otherDatas.setModalVisibility(true);
    console.log("doc id", docid);
    dispatch(activeServiceIdAction(docid));
  };

  const handleReport = (e) => {
    e.preventDefault();
    if (otherDatas.setReportModal) {
      otherDatas.setReportModal(docid);
    }
  };

  const {
    user_requirement: { service_type },
  } = data;
  return (
    <Link
      to={
        !data.has_offers && offerStatus === "Pending" // no offers
          ? `/req-details/${data.docid}/${0}`
          : data.has_offers && offerStatus === "Pending" // has offers
          ? `/req-details/${data.docid}/${1}`
          : offerStatus === "Accepted"
          ? `/req-details/${data.docid}/${2}` // accepted
          : ["Completed", "Expired", "Cancelled", "NoShow"].includes(offerStatus) // history
          ? `/req-details/${data.docid}/${3}`
          : offerStatus === "NoShow" && `/req-details/${data.docid}/${5}`
      }
      className={booked_expert || offerStatus !== "Pending"}
      state={{
        hasOffers:
          getOfferStatus(request_open_for_offers) === "Accepted" ? null : data.has_offers,
      }}
    >
      <div
        className={`border-1 p-1 md:p-2 rounded-md grid grid-cols-12 
        gap-4 border-borderColour relative min-h-[160px] 
        lg:pb-2 
        `}
      >
        <div className="col-span-3">
          <LazyImage
            src={photo || image}
            alt="dog"
            className="w-full aspect-square object-cover rounded-full"
          />
        </div>
        <div className="grid grid-cols-1 w-full col-span-9 text-left">
          <p className="text-md font-semibold capitalize">{name}</p>
          <div className="flex justify-between items-center">
            <div>
              <p className={`text-sm font-number capitalize `}>
                {breed} | {age} year {months} month old
              </p>
            </div>
            {size === "medium" && (
              <div className="flex justify-end items-center">
                <div className="w-5 h-5 mr-1 ">
                  <img src={groom} alt="" />
                </div>

                <p className="text-sm font-semibold capitalize single-line">
                  {SERVICES_TEXT[service_type]}
                </p>
              </div>
            )}
          </div>
          <p className="text-xs font-number mt-1">
            {moment(humanDate).format("ddd, MMMM Do YYYY h:mm a")}
          </p>
          {size === "small" && (
            <div className="justify-end items-center grid grid-cols-12">
              <img src={groom} alt="" className="h-full w-full r-1 col-span-1" />
              <p className="text-sm font-semibold capitalize col-span-10 ml-1">
                {SERVICES_TEXT[service_type]}
              </p>
            </div>
          )}
          <div className="flex flex-wrap gap-7 mt-2 text-black items-center">
            {/* No offers */}
            {!data.has_offers && offerStatus === "Pending" && (
              <h1 className="text-base ml-auto mr-auto mt-4">No Offer received</h1>
            )}
            {/* Has offers */}
            {data.has_offers && offerStatus === "Pending" && (
              <h1
                className="bg-darkGreen px-7 py-1 mt-2 text-white
              text-center text-base rounded-md w-full"
              >
                {offer_received_count} / 3 Offer received
              </h1>
            )}

            {/* Accepted status */}
            {offerStatus === "Accepted" ? (
              <div className="flex w-full items-center">
                <div className="w-10 h-10 mr-1">
                  <LazyImage
                    src={booked_expert.profile_picture}
                    alt="profile"
                    className="w-full aspect-square object-cover rounded-full "
                  />
                </div>

                <div>
                  <div className="flex mb-1 items-center">
                    <h1 className="text-xs font-semibold">{booked_expert.name}</h1>
                  </div>
                  {/* <h4 className='text-base font-number'>
                    {booked_expert?.mobile}
                  </h4> */}
                </div>

                <div className="ml-auto">
                  <div className="flex items-center justify-center">
                    <div className="h-3 w-3 mr-1">
                      <img src={star} alt="" className="aspect-square" />
                    </div>

                    <h1 className="text-sm font-bold">4.5</h1>
                  </div>
                  <p className="text-sm font-bold text-action ml-auto capitalize">
                    {getOfferStatus(request_open_for_offers)}
                  </p>
                </div>
              </div>
            ) : offerStatus === "Cancelled" || offerStatus === "NoShow" ? (
              <h1 className=" bg-lightDark px-7 py-1 mt-2 text-white text-center text-base rounded-md w-full">
                {SERVICE_STATUS[request_open_for_offers]}
              </h1>
            ) : offerStatus === "Expired" ? (
              <h1 className="bg-lightDark px-7 py-1 mt-2 text-white text-center text-base rounded-md w-full">
                Offer Expired
              </h1>
            ) : offerStatus === "Completed" ? (
              <div className="flex w-full items-center">
                <div className="w-10 h-10 mr-1">
                  <LazyImage
                    src={booked_expert.profile_picture}
                    alt="profile"
                    className="w-full aspect-square object-cover rounded-full"
                  />
                </div>

                <div className=" fle mb-1 items-center">
                  <h1 className="text-xs font-semibold">{booked_expert.name}</h1>
                </div>
                <div className="flex flex-col ml-auto">
                  <div className="flex items-center justify-center">
                    <div className="h-3 w-3 mr-1">
                      <img src={star} alt="" className=" aspect-square" />
                    </div>
                    <h1 className="text-sm font-bold">4.5</h1>
                  </div>

                  {/* <h4 className='text-base font-number'>
                    {booked_expert?.mobile}
                  </h4> */}

                  <div className=" text-sm font-bold text-action ml-auto capitalize">
                    Completed
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="three-dot-button flex gap-2">
          {console.log("Leave a reeview", rating_by_seeker)}
          {otherDatas.page === "myrequest" ? (
            getOfferStatus(data.request_open_for_offers) === "Completed" &&
            !rating_by_seeker ? (
              <button
                className="text-xs font-bold p-1 border-1 border-borderColour rounded text-white bg-darkGreen pointer-events-auto"
                onClick={handleReviewModal}
              >
                Leave a review
              </button>
            ) : !["Completed", "Expired", "Cancelled", "NoShow"].includes(
                getOfferStatus(data.request_open_for_offers)
              ) ? (
              <div
                className="text-xs font-bold p-1 border-1 border-borderColour rounded text-red-600 pointer-events-auto"
                onClick={handleDeletePress}
              >
                {loading ? "Loading..." : "Cancel"}
              </div>
            ) : null
          ) : null}
          {offerStatus === "Completed" && rating_by_seeker && !report && (
            <div
              className="text-xs font-bold p-1 border-1 border-borderColour rounded text-red-600 pointer-events-auto"
              onClick={handleReport}
            >
              Raise a dispute
            </div>
          )}
          {report && (
            <div className="text-xs font-bold p-1 border-1 border-borderColour rounded text-red-600 pointer-events-auto">
              Dispute Raised
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

ReqPetCard.defaultProps = {
  data: {},
  otherDatas: {},
};

ReqPetCard.propTypes = {
  data: PropTypes.object,
  otherDatas: PropTypes.object,
};

export default ReqPetCard;
