import { FILTER } from '../types';

const initialState = {
  status: 'All',
  services: 'All',

  type: 'Ongoing',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FILTER.UPDATE_SERVICES:
      return { ...state, services: payload };
    case FILTER.UPDATE_TYPE:
      return { ...state, type: payload };
    case FILTER.UPDATE_STATUS:
      return { ...state, status: payload };

    default:
      return state;
  }
};
