import React from 'react'
import { PetProfileCard } from '../'
import FileInput from '../FileInput'
import Rectangle from '../../Assets/Insurance/Rectangle.png'
import UserLogo from '../../Assets/Insurance/UserLogo.png'
import image1 from '../../Assets/Insurance/ph_dog.png'
import image2 from '../../Assets/Insurance/Mark.png'
import image3 from '../../Assets/Insurance/Right.png'
import image4 from '../../Assets/Insurance/Left.png'
import Pdf from '../../Assets/Policy_Wording.pdf'
import { BsDownload } from 'react-icons/bs'

export default function Stage4({
  formData,
  parentData,
  petDetails,
  vaccinationsProvidedList,
  setDisclaimer,
  disclaimer,
}) {
  return (
    <div className="overflow-hidden">
      <hr className="w-[80%] ml-[10%] my-4" />
      <h4 className="text-lg mt-5">
        <b>Verify Parent&apos;s details</b>
      </h4>
      <div className="flex lg:flex-nowrap mb:flex-wrap border-1 border-gray rounded-md py-4 pl-8">
        <div className="min-w-[160px] h-[156px] bg-[#FFCE76]">
          <img src={UserLogo} className="ml-8 mt-6" />
        </div>
        <div className="flex lg:flex-row mb:flex-col mb:gap-y-3 lg:gap-y-0 flex-wrap gap-x-2 lg:ml-12 mb:ml-0">
          <div className="flex lg:flex-col max-w-[240px] min-w-[240px]">
            <p className="text-darkGreen font-sans font-bold text-[15px] mb:mr-4">
              Name
            </p>
            <p className="text-[20px] font-sans break-words">
              {parentData.parentName}
            </p>
          </div>
          <div className="flex lg:flex-col max-w-[270px] min-w-[270px]">
            <p className="text-darkGreen font-sans font-bold text-[15px] mb:mr-4">
              Email
            </p>
            <p className="text-[20px] font-sans break-words">
              {parentData.EmailId}
            </p>
          </div>
          <div className="flex lg:flex-col lg:max-w-[170px] lg:min-w-[170px] mb:max-w-[220px] mb:min-w-[220px]">
            <p className="text-darkGreen font-sans font-bold text-[15px] mb:mr-4">
              Mobile No.
            </p>
            <p className="text-[20px] font-sans break-words">
              {parentData.MobileNo}
            </p>
          </div>
          <div className="flex lg:flex-col max-w-[150px] min-w-[150px]">
            <p className="text-darkGreen font-sans font-bold text-[15px] mb:mr-4">
              Gender
            </p>
            <p className="text-[20px] font-sans break-words">
              {parentData.Gender}
            </p>
          </div>
          <div className="flex lg:flex-col max-w-[240px] min-w-[240px]">
            <p className="text-darkGreen font-sans font-bold text-[15px] mb:mr-4">
              City
            </p>
            <p className="text-[20px] font-sans break-words">
              {parentData.City}
            </p>
          </div>
          <div className="flex lg:flex-col max-w-[270px] min-w-[270px]">
            <p className="text-darkGreen font-sans font-bold text-[15px] mb:mr-4">
              Occupation
            </p>
            <p className="text-[20px] font-sans break-words">
              {parentData.Occupation}
            </p>
          </div>
          <div className="flex lg:flex-col lg:max-w-[170px] lg:min-w-[170px] mb:max-w-[220px] mb:min-w-[220px]">
            <p className="text-darkGreen font-sans font-bold text-[15px]  mb:mr-4">
              Marital Status
            </p>
            <p className="text-[20px] font-sans break-words">
              {parentData.MaritalStatus}
            </p>
          </div>
          <div className="flex lg:flex-col max-w-[150px] min-w-[150px]">
            <p className="text-darkGreen font-sans font-bold text-[15px] mb:mr-4">
              Pan Card
            </p>
            <p className="text-[20px] font-sans">{parentData.PanCardNumber}</p>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap justify-between mt-4">
        <div className="w-[486px] mt-4">
          <p className="font-sans text-[20px] ">Address 1</p>
          <p className="border-b-2 border-darkGreen font-roboto text-[23px] mt-4 pb-2">
            {parentData.Address1}
          </p>
        </div>

        {parentData.Address2 !== '' && (
          <div className="w-[486px] mt-4">
            <p className="font-sans text-[20px] ">Address 2 (Optional)</p>
            <p className="border-b-2 border-darkGreen font-roboto text-[23px] mt-4 pb-2">
              {parentData.Address2}
            </p>
          </div>
        )}

        {parentData.Address3 !== '' && (
          <div className="w-[486px] mt-4">
            <p className="font-sans text-[20px] ">Address 3 (Optional)</p>
            <p className="border-b-2 border-darkGreen font-roboto text-[23px] mt-4 pb-2">
              {parentData.Address3}
            </p>
          </div>
        )}

        <div className="w-[486px] mt-4">
          <p className="font-sans text-[20px] ">Pin Code</p>
          <p className="border-b-2 border-darkGreen font-roboto text-[23px] mt-4 pb-2">
            {parentData.PinCode}
          </p>
        </div>
      </div>
      <hr className="w-[80%] ml-[10%] my-4" />
      <h4 className="text-lg mt-5">
        <b>Verify Pet&apos;s details</b>
      </h4>
      <PetProfileCard data={petDetails} className="mt-8" noEdit />
      <div className="flex flex-wrap justify-between mt-4">
        <div className="w-[486px] mt-4">
          <p className="font-sans text-[20px] ">Distinguishing Feature</p>
          <p className="border-b-2 border-darkGreen font-roboto text-[23px] mt-4 pb-2">
            {formData.DistinguishingFeature}
          </p>
        </div>

        <div className="w-[486px] mt-4">
          <p className="font-sans text-[20px] ">Tag No.</p>
          <p className="border-b-2 border-darkGreen font-roboto text-[23px] mt-4 pb-2">
            {formData.TagNo}
          </p>
        </div>

        <div className="w-[486px] mt-4">
          <p className="font-sans text-[20px] ">
            Identification Mark - Eg : Mole on the left Eye
          </p>
          <p className="border-b-2 border-darkGreen font-roboto text-[23px] mt-4 pb-2">
            {formData.IdentificationMark}
          </p>
        </div>

        <div className="w-[486px] mt-4">
          <p className="font-sans text-[20px] ">Pet Value</p>
          <p className="border-b-2 border-darkGreen font-roboto text-[23px] mt-4 pb-2">
            {formData.PetValue}
          </p>
        </div>
      </div>

      <div className="flex flex-wrap mt-14 justify-between gap-y-[31.17px]">
        <div className="border-[1px] border-[#ABB4BD] rounded-md w-[519.63px]  py-[33.87px] px-[21.17px]">
          <h6 className="font-roboto font-semibold text-[21.17px]">
            Is your pet dog(s) sound and healthy?
          </h6>
          <div className="flex mt-[31.75px]">
            <div className="w-[27.52px] h-[27.52px] rounded-[50%] border-[2.12px] border-darkGreen p-[2.23px]">
              <img src={Rectangle} />
            </div>
            <h6 className="font-sans ml-2 text-[19.05px]">
              {formData.healthy}
            </h6>
          </div>
        </div>

        <div className="border-[1px] border-[#ABB4BD] rounded-md w-[519.63px]  py-[33.87px] px-[21.17px]">
          <h6 className="font-roboto font-semibold text-[21.17px]">
            {' '}
            Is the dog&apos;s used for commercial purposes?{' '}
          </h6>
          <div className="flex mt-[31.75px]">
            <div className="w-[27.52px] h-[27.52px] rounded-[50%] border-[2.12px] border-darkGreen p-[2.23px]">
              <img src={Rectangle} />
            </div>
            <h6 className="font-sans ml-2 text-[19.05px]">
              {formData.commercial_purpose}
            </h6>
          </div>
        </div>

        <div className="border-[1px] border-[#ABB4BD] rounded-md w-[519.63px]  py-[33.87px] px-[21.17px] lg:h-[177px]">
          <h6 className="font-roboto font-semibold text-[21.17px]">
            Any Pre-existing disease/conditions Pet Dog(s) suffers from?{' '}
          </h6>
          <div className="flex mt-[31.75px]">
            <div className="w-[27.52px] h-[27.52px] rounded-[50%] border-[2.12px] border-darkGreen p-[2.23px]">
              <img src={Rectangle} />
            </div>
            <h6 className="font-sans ml-2 text-[19.05px]">
              {formData.PreExisting_disease}
            </h6>
          </div>
        </div>

        <div className="border-[1px] border-[#ABB4BD] rounded-md w-[519.63px]  py-[33.87px] px-[21.17px]">
          <p className="font-semibold font-roboto text-[18px]">
            Is the dog&apos;s already insured elsewhere?
          </p>
          <div className="flex mt-3 gap-7">
            <div className="flex mt-[31.75px]">
              <div className="w-[27.52px] h-[27.52px] rounded-[50%] border-[2.12px] border-darkGreen p-[2.23px]">
                <img src={Rectangle} />
              </div>
              <h6 className="font-sans ml-2 text-[19.05px]">
                {formData.already_insured}
              </h6>
            </div>
          </div>
          {/* Implement logic for "if else" condition showing the input part   */}
          {formData.NameOfTheInsuranceCompany !== '' && (
            <div className="border-1 border-gray-500 w-[100%] px-3 py-1 my-4 rounded-md">
              <h3>{formData.NameOfTheInsuranceCompany}</h3>
            </div>
          )}

          {formData.PolicyNumber !== '' && (
            <div className="border-1 border-gray-500 w-[100%] px-3 py-1 my-4 rounded-md">
              <h3>{formData.PolicyNumber}</h3>
            </div>
          )}
        </div>

        <div className="border-[1px] border-[#ABB4BD] rounded-md w-[519.63px] px-2 py-5 my-4">
          <p className="font-semibold font-roboto text-[18px]">
            Please select names of the vaccinations provided to your dog(s){' '}
          </p>
          <div className="grid grid-cols-2 gap-y-6 mt-3 justify-between">
            <div className="flex w-[30%]">
              <input
                className="checkboxStyle mr-2"
                type="checkbox"
                value="Rabies"
                name="vaccinationsProvided"
                // onChangeCapture={() => {
                //     setFormData({ ...formData, vaccinationsProvided: [...formData.vaccinationsProvided, 'Rabies'] })
                // }}
                checked={vaccinationsProvidedList.Rabies}
              />{' '}
              Rabies
            </div>
            <div className="flex w-[80%]">
              <input
                className="checkboxStyle mr-2"
                type="checkbox"
                value="Leptospirosis"
                name="vaccinationsProvided"
                //onChangeCapture={() => {
                //    setFormData({ ...formData, vaccinationsProvided: [...formData.vaccinationsProvided, 'Leptospirosis'] })
                //}}
                checked={vaccinationsProvidedList.Leptospirosis}
              />{' '}
              Leptospirosis
            </div>
            <div className="flex w-[80%]">
              <input
                className="checkboxStyle mr-2"
                type="checkbox"
                value="Distemper"
                name="vaccinationsProvided"
                // onChangeCapture={() => {
                //     setFormData({ ...formData, vaccinationsProvided: [...formData.vaccinationsProvided, 'Distemper'] })
                // }}
                checked={vaccinationsProvidedList.Distemper}
              />{' '}
              Distemper
            </div>
            <div className="flex w-[80%]">
              <input
                className="checkboxStyle mr-2"
                type="checkbox"
                value="Parainfluenza"
                name="vaccinationsProvided"
                // onChangeCapture={() => {
                //     setFormData({ ...formData, vaccinationsProvided: [...formData.vaccinationsProvided, 'Parainfluenza'] })
                // }}
                checked={vaccinationsProvidedList.Parainfluenza}
              />{' '}
              Parainfluenza
            </div>
            <div className="flex w-[80%]">
              <input
                className="checkboxStyle mr-2"
                type="checkbox"
                value="Hepatitis"
                name="vaccinationsProvided"
                // onChangeCapture={() => {
                //     setFormData({ ...formData, vaccinationsProvided: [...formData.vaccinationsProvided, 'Hepatitis'] })
                // }}
                checked={vaccinationsProvidedList.Hepatitis}
              />{' '}
              Hepatitis
            </div>
            <div className="flex w-[80%]">
              <input
                className="checkboxStyle mr-2"
                type="checkbox"
                value="Parvovirus"
                name="vaccinationsProvided"
                // onChangeCapture={() => {
                //     setFormData({ ...formData, vaccinationsProvided: [...formData.vaccinationsProvided, 'Parvovirus'] })
                // }}
                checked={vaccinationsProvidedList.Parvovirus}
              />{' '}
              Parvovirus
            </div>
            <div className="flex w-[80%]">
              <input
                className="checkboxStyle mr-2"
                type="checkbox"
                value="Adeno_virus"
                name="vaccinationsProvided"
                // onChangeCapture={() => {
                //     formData.vaccinationsProvided.map((element, i) => (
                //         (element === 'Adeno virus' &&
                //             (console.log('index', i),
                //                 formData.vaccinationsProvided.splice(i, 1))),

                //         element !== 'Adeno virus' &&
                //         setFormData({ ...formData, vaccinationsProvided: [...formData.vaccinationsProvided, 'Adeno virus'] })
                //     ))
                // }}
                checked={vaccinationsProvidedList.Adeno_virus}
              />{' '}
              Adeno virus
            </div>
            <div className="flex w-[80%]">
              <input
                className="checkboxStyle mr-2"
                type="checkbox"
                value="Corona"
                name="vaccinationsProvided"
                // onChangeCapture={() => {
                //     setFormData({ ...formData, vaccinationsProvided: [...formData.vaccinationsProvided, 'Corona'] })
                // }}
                checked={vaccinationsProvidedList.Corona}
              />{' '}
              Corona
            </div>
          </div>
        </div>

        <div className="border-1 border-[#ABB4BD] my-4 rounded-md lg:w-[519.63px] mb:w-full px-2 py-5">
          <div>
            <p className="font-semibold font-roboto text-[18px]">
              Upload a certificate of good health issued by a qualified
              veterinary practitioner for the Pet Dog proposed for insurance.
              (Optional) (It can be doc/pdf format)
            </p>
            <div className="flex mt-3 gap-7 ">
              <>
                <div className="flex h-full w-full items-center justify-between p-2  mt-3 gap-7  border-[0.443703px] rounded-md border-[#ABB4BD]  hover:border-[#1eaf9c] ">
                  <h6 className="mt-[5px] mb-[2px] text-[15px] font-roboto ">
                    Download Certificate of Good Health
                  </h6>
                  <BsDownload
                    scale={1.5}
                    className="text-[20px] hover:cursor-pointer "
                    onClick={() => {
                      window.open(formData.CertificateOfGoodHealth)
                    }}
                  />
                </div>
              </>
            </div>
          </div>
        </div>
        <div className="border-1 border-[#ABB4BD] my-4 rounded-md lg:w-[519.63px] mb:w-full px-2 py-5">
          <div>
            <p className="font-semibold font-roboto text-[18px]">
              Uploaded Pan Card
            </p>
            <div className="flex mt-3 gap-7 ">
              <>
                <div className="flex h-full w-full items-center justify-between p-2  mt-3 gap-7  border-[0.443703px] rounded-md border-[#ABB4BD]  hover:border-[#1eaf9c] ">
                  <h6 className="mt-[5px] mb-[2px] text-[15px] font-roboto ">
                    Download Pan Card
                  </h6>
                  <BsDownload
                    scale={1.5}
                    className="text-[20px] hover:cursor-pointer "
                    onClick={() => {
                      window.open(parentData.PanCardPhoto)
                    }}
                  />
                </div>
              </>
            </div>
          </div>
        </div>

        <div className="border-1 border-[#1eaf9c] w-full rounded-md p-4 my-8">
          <p className="font-semibold font-roboto text-[18px]">
            Upload a photograph showing the following angles. (It can be
            jpeg/png/pdf format)
          </p>
          <p className="bg-lightYellow mt-1 p-2 rounded w-[300px] text-sm mt-4">
            Each file should be less than 5MB
          </p>
          <div className="flex flex-wrap mb:ml-[15%] lg:ml-1 justify-between mt-4 ">
            <div className=" ">
              <FileInput
                file={formData.image1 ? formData.image1 : image1}
                placeholder="Upload Face Photo"
                name="image1"
                disable={true}
              />
            </div>
            <div>
              <FileInput
                file={formData.image2 ? formData.image2 : image2}
                placeholder="Upload Birth mark"
                name="image2"
                disable={true}
              />
            </div>
            <div>
              <FileInput
                file={formData.image3 ? formData.image3 : image3}
                placeholder="Upload Right Face Profile"
                name="image3"
                disable={true}
              />
            </div>
            <div>
              <FileInput
                file={formData.image4 ? formData.image4 : image4}
                placeholder="Upload Left Face Profile"
                name="image4"
                disable={true}
              />
            </div>
          </div>
        </div>

        <div className="border-[1px] border-[#ABB4BD] rounded-md w-[519.63px]  py-[33.87px] px-[21.17px]">
          <h6 className="font-roboto font-semibold text-[21.17px]">
            {' '}
            Do you own any other dog apart from the one you are insuring?{' '}
          </h6>
          <div className="flex mt-[31.75px]">
            <div className="w-[27.52px] h-[27.52px] rounded-[50%] border-[2.12px] border-darkGreen p-[2.23px]">
              <img src={Rectangle} />
            </div>
            <h6 className="font-sans ml-2 text-[19.05px]">
              {formData.ownAnyOtherDog}
            </h6>
          </div>
        </div>

        <div className="border-[1px] border-[#ABB4BD] rounded-md w-[519.63px]  py-[33.87px] px-[21.17px]">
          <h6 className="font-roboto font-semibold text-[21.17px]">
            {' '}
            Do you have access to a Veterinary Doctor for your Pet Dog(s){' '}
          </h6>
          <div className="flex mt-[31.75px]">
            <div className="w-[27.52px] h-[27.52px] rounded-[50%] border-[2.12px] border-darkGreen p-[2.23px]">
              <img src={Rectangle} />
            </div>
            <h6 className="font-sans ml-2 text-[19.05px]">
              {formData.VeterinaryDoctorForYourPet}
            </h6>
          </div>
        </div>

        <div className="border-[1px] border-[#ABB4BD] rounded-md w-[519.63px]  py-[33.87px] px-[21.17px]">
          <h6 className="font-roboto font-semibold text-[21.17px]">
            {' '}
            Have you lost any Pet Dog/s during the last three years?
          </h6>
          <div className="flex mt-[31.75px]">
            <div className="w-[27.52px] h-[27.52px] rounded-[50%] border-[2.12px] border-darkGreen p-[2.23px]">
              <img src={Rectangle} />
            </div>
            <h6 className="font-sans ml-2 text-[19.05px]">
              {formData.LostAnyPet}
            </h6>
          </div>
        </div>

        <div className="border-[1px] border-[#ABB4BD] rounded-md w-[519.63px]  py-[33.87px] px-[21.17px]">
          <h6 className="font-roboto font-semibold text-[21.17px]">
            {' '}
            Is the dog&apos;s house sound, healthy and free from vice?{' '}
          </h6>
          <div className="flex mt-[31.75px]">
            <div className="w-[27.52px] h-[27.52px] rounded-[50%] border-[2.12px] border-darkGreen p-[2.23px]">
              <img src={Rectangle} />
            </div>
            <h6 className="font-sans ml-2 text-[19.05px]">
              {formData.FreeFromVice}
            </h6>
          </div>
        </div>
      </div>

      <form>
        <div className="border-1 border-gray rounded-md my-5 py-5 px-10 font-sans text-[18px] font-normal">
          <p className="font-semibold font-roboto text-[23px]">Disclaimer</p>
          <div className="flex my-5">
            <input
              className="mr-4 h-[30px] mt-1 checkboxStyle"
              type="checkbox"
              onChangeCapture={() =>
                setDisclaimer({ ...disclaimer, first: !disclaimer.first })
              }
              value=""
              name=""
            />
            <p>
              I / we here by propose to insure the above-mentioned dog owned by
              me / us with Future Generali India Insurance Co. Ltd. Subject to
              the <a href = {Pdf} target = "_blank" className='underline underline-offset-4 text-darkGreen'>terms and conditions</a> and exclusions of the Company’s Policy. I
              / we warrant the answer to the above queries are true and complete
              in all respects and that all the Dog are accurately described and
              are in sound and good health and free from vice and that they are
              and shall be used solely for the purpose above stated. I / we
              declare that no information material to the insurance has been
              withheld and agree that this proposal shall be the basis of the
              contract between the Company and me/us and I/we agree to accept a
              policy subject to the <a href = {Pdf} target = "_blank" className='underline underline-offset-4 text-darkGreen'>terms and conditions</a>{' '}
              prescribed by the Company
            </p>
          </div>

          <div className="flex my-5">
            <input
              className="mr-4 h-[30px] checkboxStyle mt-0 mb:mt-1"
              type="checkbox"
              onChangeCapture={() =>
                setDisclaimer({ ...disclaimer, second: !disclaimer.second })
              }
              value=""
              name=""
            />
            <p>
              I have read the prospectus and agree to the <a href = {Pdf} target = "_blank" className='underline underline-offset-4 text-darkGreen'> terms and conditions </a>
              prescribed by the Company.
            </p>
          </div>

          <div className="flex my-5">
            <input
              className="mr-4 h-[30px] checkboxStyle mt-1"
              type="checkbox"
              defaultChecked={disclaimer.third}
              onChangeCapture={() =>
                setDisclaimer({ ...disclaimer, third: !disclaimer.third })
              }
              value=""
              name=""
            />
            <p>
              Go Green: We will send you a digitally signed policy document via
              e-mail and, if opted for, over WhatsApp. If you wish to receive a
              physical copy as well, kindly uncheck this box. Go Green. Go
              Paperless
            </p>
          </div>
        </div>
      </form>
    </div>
  )
}
