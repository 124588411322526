import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, GradientButton } from '../../Components'

const CreateCKYC = ({ CreateCKYCModal, setCKYCModal, formData, onCLickBack, onClickCreateRequest }) => {
  return (
    <Modal visibilty={CreateCKYCModal}>
      <div className="relative p-8 lg:min-w-max flex-auto  rounded">
        <h1 className="text-lg font-semibold text-center">Complete KYC Check</h1>
        <h3 className="text-sm font-normal text-center text-red-500">cKYC not generated for this ID</h3>

        <div className="mt-6 flex flex-row justify-between gap-8 ">
          <div className="flex flex-col w-1/2">
            <p>ID Type</p>
            <input
              value={formData.idType}
              disabled={true}
              type="text"
              name="parentName"
              className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full h-10 "
              required
            />
          </div>
          <div className="flex flex-col w-1/2">
            <p>ID Number</p>
            <input
              value={formData.idNumber}
              disabled={true}
              type="text"
              name="parentName"
              className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full h-10 font-roboto "
              required
            />
          </div>
        </div>
        <div className="flex flex-row justify-evenly mt-6">
          <GradientButton title="Back" grey={true} onPress={onCLickBack} />
          <GradientButton title="Create Request" active="true" onPress={onClickCreateRequest} />
        </div>
      </div>
    </Modal>
  )
}

CreateCKYC.defaultProps = {
  CreateCKYCModal: false,
  setCKYCModal: () => {},
}

CreateCKYC.propTypes = {
  CreateCKYCModal: PropTypes.bool.isRequired,
  setCKYCModal: PropTypes.func.isRequired,
}
export default CreateCKYC
