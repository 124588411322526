import React from "react";
import PropTypes from "prop-types";
import { toast } from 'react-toastify'
import { Modal, GradientButton, PetProfileCard } from "../Components";


const InsuranceModelAndPetSi = ({
  dogInsuranceVisibility,
  petData,
  petSI,
  setPetSI,
  onPressConfirm,
  onPressBack,
  onPressNext,
  nextBtn,
  setFormData,
}) => {
  return (
    <Modal visibilty={dogInsuranceVisibility}>
      <div className="lg:w-[830px] py-4 mb:w-full bg-white rounded-md">
        <div className="w-[90%] lg:m-10 mb:m-2 ">
          <PetProfileCard data={petData} noEdit />
        </div>

        <div className="my-4 lg:mx-10 w-full lg:w-[450px] mb:mx-3  mb:w-80">
          <label isRequired>Pet Sum Insured</label>
          <input
            required={true}
            className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full lg:w-[450px]"
            type="number"
            name="DistinguishingFeature"
            value={petSI}
            placeholder="e.g. 10000 ,20000"
            onChange={(e) => {
              setPetSI(e.target.value);
              // setFormData((prev) => ({ ...prev, PetValue: e.target.value }));
            }}
          />
           <label className="text-sm">Input Range between 10,000 to 1,00,000  </label>
        </div>

        <div className=" my-4 lg:mx-10  mb:mx-3 flex flex-column justify-start gap-x-2 item-center text-[15px] font-sans">
          <input
            type="checkbox"
            onChangeCapture={onPressConfirm}
            className="checkboxStyle4"
            disabled={petSI < 10000 || petSI > 100000}
            
            // className='w-[27.52px] h-[27.52px] accent-white '
          />
          Please confirm the above information for your pet
        </div>

        <div className="flex justify-center mt-8 gap-3">
          <GradientButton grey onPress={onPressBack} title="CLOSE" />
          <GradientButton
            grey={nextBtn}
            onPress={() => {
              if (petSI >= 10000 && petSI <= 100000 && petSI % 10000 === 0) {
                
                onPressNext();
              }
              else {
                toast.error('Please enter a valid Pet Sum Insured value')
              } 
            }
              
          }
            title="NEXT"
          />
        </div>
      </div>
    </Modal>
  );
};

InsuranceModelAndPetSi.propTypes = {
  dogInsuranceVisibility: PropTypes.bool.isRequired,
  petData: PropTypes.object.isRequired,
  onPressConfirm: PropTypes.func.isRequired,
  onPressBack: PropTypes.func.isRequired,
  onPressNext: PropTypes.func.isRequired,
  nextBtn: PropTypes.bool.isRequired,
  setPetSI: PropTypes.func.isRequired,
  petSI: PropTypes.string.isRequired,
};
export default InsuranceModelAndPetSi;
