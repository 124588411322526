import React from 'react'
import { PetProfileCard } from '../'
import FileInput from '../FileInput'
import { imageUpload } from '../../Functions/ImageUpload'
import { VACCINATIONS_PROVIDED } from '../../Constants/Constants'
import image1 from '../../Assets/Insurance/ph_dog.png'
import image2 from '../../Assets/Insurance/Mark.png'
import image3 from '../../Assets/Insurance/Right.png'
import image4 from '../../Assets/Insurance/Left.png'

export default function Stage2({
  formData,
  handlePetInput,
  setFormData,
  petDetails,
}) {
  return (
    <>
      <h4 className="text-lg mt-[35.03px] mb-[21.4px]">
        <b>Enter your pet&apos;s details</b>
      </h4>

      <PetProfileCard data={petDetails} className="mt-4" noEdit />

      <form action="" method="POST">
        <div className="flex flex-wrap lg:justify-between mb:justify-center">
          <div className="my-4 w-full lg:w-[450px]">
            <div className="flex flex-row  items-center ">
              <label>Distinguishing Feature</label>
              <label className="text-red-800 text-[2rem] ">*</label>
            </div>
            <input
              required={true}
              className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full lg:w-[450px]"
              type="text"
              name="DistinguishingFeature"
              value={formData.DistinguishingFeature}
              onChange={handlePetInput}
            />
          </div>
          <div className="my-4 w-full lg:w-[450px]">
            <div className="flex flex-row  items-center ">
              <label>Tag No</label>
              <label className="text-red-800 text-[2rem] opacity-0	 ">*</label>
            </div>

            <input
              className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full lg:w-[450px]"
              type="number"
              name="TagNo"
              value={formData.TagNo}
              onChange={handlePetInput}
            />
          </div>
          <div className="my-4 w-full lg:w-[450px]">
            <div className="flex flex-row  items-center ">
              <label>Identification Mark - Eg : Mole on the left Eye</label>
              <label className="text-red-800 text-[2rem] ">*</label>
            </div>

            <input
              className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full lg:w-[450px]"
              type="text"
              name="IdentificationMark"
              value={formData.IdentificationMark}
              onChange={handlePetInput}
            />
          </div>
          <div className="my-4 w-full lg:w-[450px]">
            <div className="flex flex-row  items-center ">
              <p>Pet Value</p>
              <label className="text-red-800 text-[2rem] opacity-0	 ">*</label>
            </div>

            <input
              className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full lg:w-[450px]"
              type="number"
              name="PetValue"
              required={true}
              value={formData.PetValue}
              onChange={handlePetInput}
            />
          </div>
        </div>

        <hr className="w-[80%] ml-[10%] my-4" />

        <div className="flex flex-wrap lg:justify-between mb:justify-center">
          <div className="border-1 border-[#1eaf9c] my-4 rounded-md w-full lg:w-[450px] px-2 py-5">
            <div>
              <p className="font-semibold font-roboto text-[18px]">
                Is your pet dog(s) sound and healthy?
                <label className="text-red-800 text-[1rem] "> *</label>
              </p>
              <div className="flex mt-3 gap-7">
                <div className="flex justify-center space-x-2">
                  <input
                    type="radio"
                    name="healthy"
                    className="radioStyle "
                    value={formData.healthy}
                    defaultChecked={formData.healthy === 'Yes' ? true : false}
                    onChange={() => {
                      setFormData({ ...formData, healthy: 'Yes' })
                    }}
                  />{' '}
                  <span>Yes</span>
                </div>
                <div className="flex justify-center space-x-2 ">
                  <input
                    type="radio"
                    value={formData.healthy}
                    name="healthy"
                    className="radioStyle"
                    defaultChecked={formData.healthy === 'No' ? true : false}
                    onChange={() => {
                      setFormData({ ...formData, healthy: 'No' })
                    }}
                  />{' '}
                  <p>No</p>
                </div>
              </div>
            </div>
          </div>

          <div className="border-1 border-[#1eaf9c] my-4 rounded-md w-full lg:w-[450px] px-2 py-5">
            <div>
              <p className="font-semibold font-roboto text-[18px]">
                Is the dog&apos;s used for commercial purposes?
              </p>
              <div className="flex mt-3 gap-7">
                <div className="flex justify-center space-x-2 ">
                  <input
                    type="radio"
                    value="Yes"
                    className="radioStyle"
                    name="commercial_purpose"
                    defaultChecked={
                      formData.commercial_purpose === 'Yes' ? true : false
                    }
                    onChange={() => {
                      setFormData({ ...formData, commercial_purpose: 'Yes' })
                    }}
                  />{' '}
                  <label>Yes</label>
                </div>
                <div className="flex justify-center space-x-2 ">
                  <input
                    type="radio"
                    value="No"
                    className="radioStyle"
                    name="commercial_purpose"
                    defaultChecked={
                      formData.commercial_purpose === 'No' ? true : false
                    }
                    onChange={() => {
                      setFormData({ ...formData, commercial_purpose: 'No' })
                    }}
                  />{' '}
                  <label>No</label>
                </div>
              </div>
            </div>
          </div>

          <div className="border-1 border-[#1eaf9c] max-h-[125px] my-4 rounded-md w-full lg:w-[450px] px-2 py-5">
            <div>
              <p className="font-semibold font-roboto text-[18px]">
                Any Pre-existing disease/conditions Pet Dog(s) suffers from?
              </p>
              <div className="flex mt-3 gap-7">
                <div className="flex justify-center space-x-2 ">
                  <input
                    type="radio"
                    value="Yes"
                    className="radioStyle"
                    name="PreExisting_disease"
                    defaultChecked={
                      formData.PreExisting_disease === 'Yes' ? true : false
                    }
                    onChange={() => {
                      setFormData({ ...formData, PreExisting_disease: 'Yes' })
                    }}
                  />{' '}
                  <label>Yes</label>
                </div>
                <div className="flex justify-center space-x-2 ">
                  <input
                    type="radio"
                    value="No"
                    className="radioStyle"
                    name="PreExisting_disease"
                    defaultChecked={
                      formData.PreExisting_disease === 'No' ? true : false
                    }
                    onChange={() => {
                      setFormData({ ...formData, PreExisting_disease: 'No' })
                    }}
                  />{' '}
                  <label>No</label>
                </div>
              </div>
            </div>
          </div>

          <div className="border-1 border-[#1eaf9c] my-4 rounded-md w-full lg:w-[450px] px-2 py-5">
            <div>
              <p className="font-semibold font-roboto text-[18px]">
                Is the dog&apos;s already insured elsewhere?
              </p>
              <div className="flex mt-3 justify-between ">
                <div className="flex justify-center space-x-2 ">
                  <input
                    type="radio"
                    value="Insured"
                    className="radioStyle"
                    name="already_insured"
                    defaultChecked={
                      formData.already_insured === 'Insured' ? true : false
                    }
                    onChange={() => {
                      setFormData({ ...formData, already_insured: 'Insured' })
                    }}
                  />{' '}
                  <label>Insured</label>
                </div>
                <div className="flex justify-center space-x-2 ml-2 ">
                  <input
                    type="radio"
                    value="Not_Insured "
                    className="radioStyle"
                    name="already_insured"
                    defaultChecked={
                      formData.already_insured === 'Not Insured' ? true : false
                    }
                    onChange={() => {
                      setFormData({
                        ...formData,
                        already_insured: 'Not Insured',
                      })
                    }}
                  />{' '}
                  <label>Not Insured</label>
                </div>
                <div className="flex justify-center space-x-2 ">
                  <input
                    type="radio"
                    value="Insured_previousl"
                    className="radioStyle"
                    name="already_insured"
                    defaultChecked={
                      formData.already_insured === 'Insured previously'
                        ? true
                        : false
                    }
                    onChange={() => {
                      setFormData({
                        ...formData,
                        already_insured: 'Insured previously',
                      })
                    }}
                  />{' '}
                  <label>Insured previously</label>
                </div>
              </div>
              {/* Implement logic for "if else" condition showing the input part   */}
              {formData.already_insured !== 'Not Insured' && (
                <>
                  <input
                    type="text"
                    className="border-1 border-gray-500 w-[100%] px-3 py-1 my-4 rounded-md"
                    placeholder="Name of the Insurance Company"
                    name="NameOfTheInsuranceCompany"
                    onChange={handlePetInput}
                  />
                  <input
                    type="number"
                    className="border-1 border-gray-500 w-[100%] px-3 py-1 my-2 rounded-md"
                    placeholder="Policy number"
                    name="PolicyNumber"
                    onChange={handlePetInput}
                  />
                </>
              )}
            </div>
          </div>

          <div
            className={`border-1 border-[#1eaf9c] my-4 rounded-md w-full lg:w-[450px] lg:mt-[-80px] ${
              formData.already_insured !== 'Not Insured'
                ? 'lg:mt-[-80px]'
                : 'lg:mt-[15px]'
            } px-2 py-5`}
          >
            <div>
              <p className="font-semibold font-roboto text-[18px]">
                Please select names of the vaccinations provided to your dog(s){' '}
                <span className="font-semibold font-roboto text-[13px]">
                  (At least One vaccination should be selected)
                </span>
                <label className="text-red-800 text-[1rem] "> *</label>
              </p>
              <div className="grid grid-cols-2 gap-y-6 mt-3 justify-between">
                {VACCINATIONS_PROVIDED.map((item, i) => (
                  <div className="flex w-[80%]" key={i}>
                    <input
                      className="checkboxStyle mr-2"
                      type="checkbox"
                      value=""
                      name="vaccinationsProvided"
                      defaultChecked={formData.vaccinationsProvided[item.value]}
                      onChangeCapture={() => {
                        setFormData({
                          ...formData,
                          vaccinationsProvided: {
                            ...formData.vaccinationsProvided,
                            [item.value]:
                              !formData.vaccinationsProvided[item.value],
                          },
                        })
                      }}
                    />{' '}
                    {item.title}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="border-1 border-[#1eaf9c] my-4 rounded-md w-full lg:w-[450px] px-2 py-5">
            <div>
              <div className="flex flex-row  items-center ">
                <p className="font-semibold font-roboto text-[18px]">
                  Upload a certificate of good health issued by a qualified
                  veterinary practitioner for the Pet Dog proposed for
                  insurance. (Optional) (It can be doc/pdf format)
                  {/* <label className="text-red-800 ml-1">*</label> */}
                </p>
              </div>

              <div className="flex mt-3 gap-7 border-1 border-yellow">
                <input
                  type="file"
                  placeholder="Browse Files (File should be less than 5MB)"
                  name="CertificateOfGoodHealth"
                  accept='image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                  onChange={async (e) => {
                    const imageUrl = await imageUpload(
                      'petInsurance',
                      e.target.files[0],
                    )

                    setFormData({
                      ...formData,
                      CertificateOfGoodHealth: imageUrl,
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <hr className="w-[80%] ml-[10%] my-4" />

        {/* Implement the Upload photograph part Here */}
        <div className="flex mb:justify-center">
          <div className="border-1 border-[#1eaf9c] rounded-md p-4 my-4 w-full ">
            <p className="font-semibold font-roboto text-[18px]">
              Upload a photograph showing the following angles. (It can be
              jpeg/png)
            </p>
            <p className="bg-lightYellow mt-1 p-2 rounded w-[300px] text-sm mt-4">
              Each file should be less than 5MB
            </p>
            <div className="flex flex-wrap mb:ml-[15%] lg:ml-1 justify-between mt-4">
              <div>
                <FileInput
                  displayImage={image1}
                  file={formData.image1}
                  placeholder="Upload Face Photo"
                  name="image1"
                  onChange={async (e) => {
                    const imageUrl = await imageUpload(
                      'petInsurance',
                      e.target.files[0],
                    )

                    setFormData({
                      ...formData,
                      image1: imageUrl,
                    })
                  }}
                />
              </div>
              <div>
                <FileInput
                  displayImage={image2}
                  file={formData.image2}
                  placeholder="Upload Birth mark"
                  name="image2"
                  onChange={async (e) => {
                    const imageUrl = await imageUpload(
                      'petInsurance',
                      e.target.files[0],
                    )

                    setFormData({
                      ...formData,
                      image2: imageUrl,
                    })
                  }}
                />
              </div>
              <div>
                <FileInput
                  displayImage={image3}
                  file={formData.image3}
                  placeholder="Upload Right Face Profile"
                  name="image3"
                  onChange={async (e) => {
                    const imageUrl = await imageUpload(
                      'petInsurance',
                      e.target.files[0],
                    )

                    setFormData({
                      ...formData,
                      image3: imageUrl,
                    })
                  }}
                />
              </div>
              <div>
                <FileInput
                  displayImage={image4}
                  file={formData.image4}
                  placeholder="Upload Left Face Profile"
                  name="image4"
                  onChange={async (e) => {
                    const imageUrl = await imageUpload(
                      'petInsurance',
                      e.target.files[0],
                    )

                    setFormData({
                      ...formData,
                      image4: imageUrl,
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <hr className="w-[80%] ml-[10%] my-4" />

        <div className="flex flex-wrap lg:justify-between mb:justify-center">
          <div className="border-1 border-[#1eaf9c] my-4 rounded-md w-full lg:w-[450px] px-2 py-5">
            <div>
              <p className="font-semibold font-roboto text-[18px]">
                {' '}
                Do you own any other dog apart from the one you are insuring?{' '}
                {/* <label className="text-red-800 text-[1rem] "> *</label> */}
              </p>
              <div className="flex mt-3 gap-7">
                <div className="flex justify-center space-x-2 ">
                  <input
                    type="radio"
                    value="Yes"
                    className="radioStyle"
                    name="ownAnyOtherDog"
                    defaultChecked={
                      formData.ownAnyOtherDog === 'Yes' ? true : false
                    }
                    onChange={() => {
                      setFormData({ ...formData, ownAnyOtherDog: 'Yes' })
                    }}
                  />{' '}
                  <label>Yes</label>
                </div>
                <div className="flex justify-center space-x-2 ">
                  <input
                    type="radio"
                    value="No"
                    className="radioStyle"
                    name="ownAnyOtherDog"
                    defaultChecked={
                      formData.ownAnyOtherDog === 'No' ? true : false
                    }
                    onChange={() => {
                      setFormData({ ...formData, ownAnyOtherDog: 'No' })
                    }}
                  />{' '}
                  <label>No</label>
                </div>
              </div>
            </div>
          </div>

          <div className="border-1 border-[#1eaf9c] my-4 rounded-md w-full lg:w-[450px] px-2 py-5">
            <div>
              <p className="font-semibold font-roboto text-[18px]">
                {' '}
                Do you have access to a Veterinary Doctor for your Pet Dog(s){' '}
                {/* <label className="text-red-800 text-[1rem] "> *</label> */}
              </p>
              <div className="flex mt-3 gap-7">
                <div className="flex justify-center space-x-2 ">
                  <input
                    type="radio"
                    value="Yes"
                    className="radioStyle"
                    name="VeterinaryDoctorForYourPet"
                    defaultChecked={
                      formData.VeterinaryDoctorForYourPet === 'Yes'
                        ? true
                        : false
                    }
                    onChange={() => {
                      setFormData({
                        ...formData,
                        VeterinaryDoctorForYourPet: 'Yes',
                      })
                    }}
                  />{' '}
                  <label>Yes</label>
                </div>
                <div className="flex justify-center space-x-2 ">
                  <input
                    type="radio"
                    value="No"
                    className="radioStyle"
                    name="VeterinaryDoctorForYourPet"
                    defaultChecked={
                      formData.VeterinaryDoctorForYourPet === 'No'
                        ? true
                        : false
                    }
                    onChange={() => {
                      setFormData({
                        ...formData,
                        VeterinaryDoctorForYourPet: 'No',
                      })
                    }}
                  />{' '}
                  <label>No</label>
                </div>
              </div>
            </div>
          </div>

          <div className="border-1 border-[#1eaf9c] max-h-[125px] my-4 rounded-md w-full lg:w-[450px] px-2 py-5">
            <div>
              <p className="font-semibold font-roboto text-[18px]">
                Have you lost any Pet Dog/s during the last three years?
                {/* <label className="text-red-800 text-[1rem] "> *</label> */}
              </p>
              <div className="flex mt-3 gap-7">
                <div className="flex justify-center space-x-2 ">
                  <input
                    type="radio"
                    value="Yes"
                    className="radioStyle"
                    defaultChecked={
                      formData.LostAnyPet === 'Yes' ? true : false
                    }
                    name="LostAnyPet"
                    onChange={() => {
                      setFormData({ ...formData, LostAnyPet: 'Yes' })
                    }}
                  />{' '}
                  <label>Yes</label>
                </div>
                <div className="flex justify-center space-x-2 ">
                  <input
                    type="radio"
                    value="No"
                    className="radioStyle"
                    name="LostAnyPet"
                    defaultChecked={formData.LostAnyPet === 'No' ? true : false}
                    onChange={() => {
                      setFormData({ ...formData, LostAnyPet: 'No' })
                    }}
                  />{' '}
                  <label>No</label>
                </div>
              </div>
            </div>
          </div>

          <div className="border-1 border-[#1eaf9c] max-h-[125px] my-4 rounded-md w-full lg:w-[450px] px-2 py-5">
            <div>
              <p className="font-semibold font-roboto text-[18px]">
                {' '}
                Is the dog’s house sound, healthy and free from vice?
                <label className="text-red-800 text-[1rem] "> *</label>
              </p>

              <div className="flex mt-3 gap-7">
                <div className="flex justify-center space-x-2 ">
                  <input
                    type="radio"
                    value="No"
                    className="radioStyle"
                    name="FreeFromVice"
                    defaultChecked={
                      formData.FreeFromVice === 'Yes' ? true : false
                    }
                    onChange={() => {
                      setFormData({ ...formData, FreeFromVice: 'Yes' })
                    }}
                  />{' '}
                  <label>Yes</label>
                </div>
                <div className="flex justify-center space-x-2 ">
                  <input
                    type="radio"
                    value="No"
                    className="radioStyle"
                    name="FreeFromVice"
                    defaultChecked={
                      formData.FreeFromVice === 'No' ? true : false
                    }
                    onChange={() => {
                      setFormData({ ...formData, FreeFromVice: 'No' })
                    }}
                  />{' '}
                  <label>No</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}
