import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal ,GradientButton } from '../../Components'


const CheckCKYC = ({ checkCKYC, loading , formData ,setFormData ,onClickNext,onClickCreateRequest}) => {

  const idOptions = [
    {
      value:'',
      label:'Select ID Type'
    },
    {
      value: "AADHAAR",
      label: 'Aadhaar',
    },
    {
      value: 'PAN',
      label: 'PAN',
    },
    {
      value: 'DL',
      label: 'Driving License',
    },
    {
      value: 'PASSPORT',
      label: 'Passport',
    },
    {
      value: 'VOTER',
      label: 'Voter ID',
    },
    {
      value: 'CKYC',
      label: 'CKYC',
    },
    {
      value: 'CIN',
      label: 'CIN',
    },
  ]
  const genderOptions = [
    {
      value:'',
      label:"Select Gender"
    },
    {
      value: "M",
      label:'Male'
    },
    {
      value: "F",
      label:"Female"
    },
  ]
  return (
    <Modal visibilty={checkCKYC}>
      <div className="relative p-8 lg:min-w-max flex-auto  rounded">
        <h1 className="text-lg font-semibold text-center">
          Complete KYC Check
        </h1>
        <h3 className="text-md font-normal text-center">
          Enter your KYC details
        </h3>
        <div className="flex flex-col w-full lg:w-[700px]">
          <p>Full Name</p>
          <input
            value={formData.fullName}
            onChange={(e) => {
              setFormData({ ...formData, fullName: e.target.value })
            }}
            type="text"
            name="parentName"
            className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full "
            required
          />
        </div>

        <div className="mt-6 flex flex-row justify-between gap-8 ">
          <div className="flex flex-col w-1/2">
            <p>ID Type</p>
            <select
              value={formData.idType}
              onChange={(e) => {
                setFormData({ ...formData, idType: e.target.value })
              }}
              type="text"
              name="idType"
              className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full h-10 bg-white"
              required
            >
              {idOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col w-1/2">
            <p>ID Number</p>
            <input
              value={formData.idNumber}
              onChange={(e) => {
                setFormData({ ...formData, idNumber: e.target.value })
              }}
              type="text"
              name="parentName"
              className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full h-10 font-roboto"
              required
            />
          </div>
        </div>

        <div className="mt-6 flex flex-row justify-between gap-8 ">
          <div className="flex flex-col w-1/2">
            <p>Date of Birth</p>
            <input
              value={formData.DOB}
              onChange={(e) => {
                setFormData({ ...formData, DOB: e.target.value })
              }}
              type="date"
              form='dd-mm-yyyy'
              name="parentName"
              className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full h-10 "
              required
            />
          </div>
          <div className="flex flex-col w-1/2">
            <p>Gender</p>
            <select
              value={formData.gender}
              onChange={(e) => {
                setFormData({ ...formData, gender: e.target.value })
              }}
              type="text"
              name="idType"
              className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full h-10 bg-white"
              required
            >
              {genderOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='flex flex-row justify-evenly mt-6'> 
          <GradientButton 
            title="Create Request"
            active= "true"
            onPress={onClickCreateRequest}
          />
          <GradientButton 
            title="Next"
            onPress={onClickNext}
            loading={loading}
            disabled={loading}
          />
        </div>
      </div>
    </Modal>
  )
}

CheckCKYC.defaultProps = {
  submitModalVisibility: false,
  setSubmitModalVisibility: () => {},
  name: '',
  previousExperts: [],
  expertId: null,
  setExpertId: () => {},
  newRequest: () => {},
  next: () => {},
  loading: false,
}

CheckCKYC.propTypes = {
  submitModalVisibility: PropTypes.string.isRequired,
  setSubmitModalVisibility: PropTypes.func.isRequired,
}
export default CheckCKYC
