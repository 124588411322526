import React from 'react'
import PropTypes from 'prop-types'
import breed from '../Assets/icons/Breed.png'
import weight from '../Assets/icons/Weight.png'
import gender from '../Assets/icons/Gender.png'
import age from '../Assets/icons/Age.png'
import location from '../Assets/icons/Location.png'
import { FaEdit } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import LazyImage from './LazyImage'
import { WEIGHT_OPTIONS } from '../Constants/Constants'

const PetProfileCard = ({ className, data, noEdit, noLocation }) => {
  const details = [
    { icon: breed, title: 'Breed', value: data.breed || 'Thai Cat' },
    {
      icon: weight,
      title: 'Weight',
      value:
        WEIGHT_OPTIONS.find((item) => item.value === data.weight)?.label ||
        '03-05 Kg',
    },
    { icon: gender, title: 'Gender', value: data.gender || 'Female' },
    {
      icon: age,
      title: 'Age',
      value: `${data.age} Year ${data.months} months` || '2 Year 04 Months',
    },
  ]

  return (
    <div
      className={`border-1 p-2 md:p-3 rounded-md grid grid-cols-12 gap-4 border-borderColour ${className}`}
    >
      <div className="col-span-2">
        <LazyImage
          src={data.photo || data.image}
          alt="dog"
          className="w-full aspect-square object-cover rounded-full"
        />
      </div>
      <div className="md:ml-3 w-full col-span-10">
        <div className="flex justify-between items-center mb-2">
          <p className="text-2xl font-semibold capitalize">{data.name}</p>
          {!noEdit && (
            <Link to={`/edit-pet/${data.docid}`} state={{ id: data.docid }}>
              <div className="flex px-3 h-7 items-center mr-4 bg-action text-sm text-white font-semibold rounded-md cursor-pointer">
                <FaEdit fill="white" />
                <h1 className="ml-1 text-white">Edit</h1>
              </div>
            </Link>
          )}
        </div>
        <div
          className={`${
            noLocation ? 'grid grid-cols-2' : 'flex flex-wrap'
          } gap-3 mb-3`}
        >
          {details.map(({ title, icon, value }) => (
            <div key={title}>
              <div className="flex items-center mr-4 last:mr-0">
                <img src={icon} alt="icon" className="w-4 h-4 mr-1" />
                <p className="text-sm text-light">{title}</p>
              </div>
              <p className="text-base font-number capitalize">{value}</p>
            </div>
          ))}
        </div>
        {!noLocation && (
          <div>
            <div className="flex items-center mr-2 md:mr-4 last:mr-0">
              <img src={location} alt="icon" className="w-4 h-4 mr-1" />
              <p className="text-sm text-light">Location</p>
            </div>
            <p className="text-base font-number">{`${data.building}, ${data.location_address}`}</p>
          </div>
        )}
      </div>
    </div>
  )
}

PetProfileCard.defaultProps = {
  className: '',
  data: {},
  noEdit: false,
  noLocation: false,
}

PetProfileCard.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  noEdit: PropTypes.bool,
  noLocation: PropTypes.bool,
}

export default PetProfileCard
