import React from 'react'
import PropTypes from 'prop-types'
import { TiTick } from 'react-icons/ti'
import BeatLoader from 'react-spinners/BeatLoader'

const override = {
  display: 'block',
  // margin: "0 auto",
  borderColor: 'red',
}

const GradientButton = ({
  className,
  title,
  size,
  onPress,
  type,
  disabled,
  active,
  done,
  grey,
  loading,
}) => {
  return (
    <button
      onClick={() => {
        if (!loading) {
          onPress()
        }
      }}
      type={type}
      disabled={disabled}
      className={`button flip-animation ${className} ${
        disabled ? 'disabled-btn' : ''
      } disabled:bg-black button-${size} ${
        !active ? 'button-bg' : 'bg-action'
      } main-button ${grey && 'grey-gradient'}`}
    >
      {!loading ? (
        <div className="flex justify-center">
          {done && (
            <TiTick
              className="border-2 mr-1 border-white bg-white border-solid rounded-full mt-px"
              size="20"
              fill="orange"
            />
          )}
          <p className="font-semibold text-white">{title}</p>
        </div>
      ) : (
        <BeatLoader
          color={'#fff'}
          loading={loading}
          cssOverride={override}
          size={8}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      )}
    </button>
  )
}

GradientButton.defaultProps = {
  size: 'md',
  type: 'button',
  onPress: () => {},
  disabled: false,
  grey: false,
  loading: false,
}

GradientButton.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  onPress: PropTypes.func,
  type: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xs']),
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  done: PropTypes.bool,
  grey: PropTypes.bool,
  loading: PropTypes.bool,
}

export default GradientButton
