import { onAuthStateChanged } from 'firebase/auth'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { PrivateRoute } from '../Components'
import { auth } from '../firebase'
import { Footer, Header } from '../Layouts'
import {
  AddDetailsScreen,
  AddPetPage,
  CreateRequestPage,
  DashboardPage,
  EditPetPage,
  LoginPage,
  MyPetProfileScreen,
  MyRequestPage,
  NotificationsPage,
  OnboardingPage,
  RequestsPage,
  CongratsPage,
  PetInsurance,
  PetPathology,
  FailureScreen,
} from '../Pages'

import { removeUser, setUser } from '../Redux/actions/authActions'
import { fetchTrendingExperts } from '../Redux/actions/expertActions'
import { fetchUserPets } from '../Redux/actions/petActions'
import { fetchUserServices } from '../Redux/actions/serviceActions'
import { fetchCompletedExperts } from '../Redux/actions/completedNearbyExpertsActions'
import ReqDetails from '../Pages/ReqDetails'
import { fetchUserNotifications } from '../Redux/actions/notificationsActions'
import { getPathologyPackageTest } from '../Redux/actions/petPathologyActions'
import { addAllPets } from '../Redux/actions/petActions'
import axios from 'axios'
import { useRollbar } from '@rollbar/react'

const Navigator = () => {
  const dispatch = useDispatch()
  const rollbar = useRollbar()
  // function creatNewError() {
  //   rollbar.error('Heelo')
  // }
  // creatNewError()

  useEffect(() => {
    let interval = ''

    onAuthStateChanged(auth, (user) => {
      if (user?.uid) {
        dispatch(setUser(user))
        dispatch(fetchUserPets())
        dispatch(fetchTrendingExperts())
        dispatch(fetchCompletedExperts())
        dispatch(fetchUserServices())
        dispatch(fetchUserNotifications())
        dispatch(getPathologyPackageTest())
        interval = setInterval(() => {
          dispatch(fetchUserNotifications())
        }, 60 * 1000)
      } else {
        dispatch(removeUser())
      }
    })
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [])

  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route
          path="/:id"
          element={
            <PrivateRoute>
              <CreateRequestPage />
            </PrivateRoute>
          }
        />
        {/* <Route path="/login" element={<LoginPage />} /> */}
        {/* <Route
          path="/add-user-details"
          element={
            <PrivateRoute to="/add-user-details">
              <AddDetailsScreen />
            </PrivateRoute>
          }
        /> */}
        <Route path="/failure/:txnId" element={<FailureScreen />} />
        {/* <Route
          path="/onboarding"
          element={
            <PrivateRoute to="/onboarding">
              <OnboardingPage />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          path="/add-pet"
          element={
            <PrivateRoute>
              <AddPetPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-pet/:petId"
          element={
            <PrivateRoute>
              <EditPetPage />
            </PrivateRoute>
          }
        /> */}
        <Route path="/congrats/:txnId" element={<CongratsPage />} />
        {/* <Route
          path="/my-requests"
          element={
            <PrivateRoute>
              <MyRequestPage />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          path="/my-pet-profile"
          element={
            <PrivateRoute>
              <MyPetProfileScreen />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          path="/notifications"
          element={
            <PrivateRoute>
              <NotificationsPage />
            </PrivateRoute>
          }
        /> */}
        <Route
          path="/create-request"
          element={
            <PrivateRoute>
              <CreateRequestPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/pet-insurance/:petId/:proposalId?"
          element={
            <PrivateRoute>
              <PetInsurance />
            </PrivateRoute>
          }
        />
        {/* <Route
          path="/pet-pathology"
          element={
            <PrivateRoute>
              <PetPathology />
            </PrivateRoute>
          }
        />
        <Route
          path="/view-requests"
          element={
            <PrivateRoute>
              <RequestsPage />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          path="/req-details/:reqId/:section"
          element={
            <PrivateRoute>
              <ReqDetails />
            </PrivateRoute>
          }
        /> */}
      </Routes>
      <Footer />
      <ToastContainer />
    </BrowserRouter>
  )
}

export default Navigator
