import { USER } from '../types';

const initialState = {
  //   id: localStorage.getItem('@user_id'),
  user: null,
  loading: true,
  seeker_object: {},
  user_query: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case USER.SET_USER:
      return { ...state, user: payload, loading: false };
    case USER.SET_SEEKER_DATA:
      return { ...state, seeker_object: payload };
    case USER.SET_USER_QUERY:
      console.log('ssss')
      return { ...state, user_query: payload };

    case USER.REMOVE_USER:
      return { ...initialState, loading: false, user_query: state.user_query };

    default:
      return state;
  }
};
