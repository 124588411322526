import board from "../Assets/icons/board.png";
import dogwalk from "../Assets/icons/dogwalk.png";
import EarCleaning from "../Assets/icons/EarCleaning.png";
import groom from "../Assets/icons/groom.png";
import Hair from "../Assets/icons/Hair.png";
import Nails from "../Assets/icons/Nails.png";
import Paw from "../Assets/icons/Paw.png";
import sit from "../Assets/icons/sit.png";
import train from "../Assets/icons/train.png";
import vet from "../Assets/icons/vet.png";
import cat1 from "../Assets/removeable/cat1.jpg";
import cat2 from "../Assets/removeable/cat2.jpg";
import cat3 from "../Assets/removeable/cat3.jpg";
import cert1 from "../Assets/removeable/cert.webp";
import cert2 from "../Assets/removeable/cert2.jpg";
import cert3 from "../Assets/removeable/cert3.jpg";
import cert4 from "../Assets/removeable/cert4.png";
import dog1 from "../Assets/removeable/dog1.jpg";
import dog2 from "../Assets/removeable/dog2.jpg";
import dog3 from "../Assets/removeable/dog3.jpg";
import Casper from "../Assets/removeable/Ellipse 2.png";
import Rosy from "../Assets/removeable/Ellipse 5.png";
import Person1 from "../Assets/removeable/Person1.png";
import Person2 from "../Assets/removeable/Person2.png";
import LGLOGO from "../Assets/fglogo.png";
import Insurance from "../Assets/icons/Insurance.png";
import pathology from "../Assets/pathology.png";

export const petInsuranceAdddOns = {
  FDH0001:"Main Cover (Funeral Cost,Terminal Illness,Surgery & Hospitalisation, Death)",
  FDH0005:"Third Party Liability Cover",
  FDH0006:"Lost and Stolen Cover",
  FDH0007:"Emergency Pet Minding Cover",
  FDH0008:"Veterinary Consultation & Doctor on Call Cover"
}

export const behaviouralTrainingOptions = [
  {
    value: "3 sessions",
  },
  {
    value: "4 sessions",
  },
  {
    value: "7 sessions",
  },
  {
    value: "11 sessions",
  },
];

export const SERVICE_OPTIONS = {
  VET: "vet",
  TRAINING: "pet_trainers",
  GROOMING: "pet_grooming",
  BOARDING: "pet_boarders",
  SITTING: "pet_sitters",
  WALKING: "dog_walking",
  INSURANCE: "dog_insurance",
  PATHOLOGY: "pathology",
};

export const TRAINING_OPTIONS = {
  behavioralTrainingThree: "Behavioral 3 sessions",
  behavioralTrainingSix: "Behavioral 6 sessions",
  behavioralTrainingTwelve: "Behavioral 12 sessions",
  firstTrainingTwelve: "First Training 12 sessions",
  firstTrainingSix: "First Training 6 sessions",
  firstTrainingThree: "First Training 3 sessions",
  reTrainingTwelve: "Retraining 12 sessions",
  reTrainingSix: "Retraining 6 sessions",
  reTrainingThree: "Retraining 3 sessions",
  obedienceTrainingThree: "Obedience 3 sessions",
  obedienceTrainingSix: "Obedience 6 sessions",
  obedienceTrainingTwelve: "Obedience 12 sessions",
};

export const SERVICES_TEXT = {
  vet: "Veterinarian",
  pet_trainers: "Training",
  pet_grooming: "Grooming",
  pet_boarders: "Boarding",
  pet_sitters: "Sitting",
  dog_walking: "Dog Walking",
};

export const ServiceOptions = [
  {
    image: train,
    value: SERVICE_OPTIONS.TRAINING,
    title: "Training",
    isDisabled: true,
  },
  {
    image: groom,
    value: SERVICE_OPTIONS.GROOMING,
    title: "Grooming",
    isDisabled: true,
  },
  {
    image: board,
    value: SERVICE_OPTIONS.BOARDING,
    title: "Boarding",
    isDisabled: true,
  },
  {
    image: dogwalk,
    value: SERVICE_OPTIONS.WALKING,
    title: "Dog Walking",
    isDisabled: true,
  },
  {
    image: sit,
    value: SERVICE_OPTIONS.SITTING,
    title: "Sitting",
    isDisabled: true,
  },
  {
    image: vet,
    value: SERVICE_OPTIONS.VET,
    title: "Veterinarian",
    isDisabled: true,
  },
  {
    image: Insurance,
    value: SERVICE_OPTIONS.INSURANCE,
    title: "Pet Insurance",
    isDisabled: false,
  },
  {
    image: pathology,
    value: SERVICE_OPTIONS.PATHOLOGY,
    title: "Pathology",
    isDisabled: true,
  },
];

export const VetServicesOptions = [
  {
    image: vet,
    title: "Vaccination",
    value: "vaccination",
  },
  {
    image: board,
    title: "Deworming",
    value: "deworming",
  },
  {
    image: board,
    title: "Accident / Minor injury",
    value: "accidentInjury",
  },
  {
    image: groom,
    title: "General Checkup",
    value: "generalCheckup",
  },
  {
    image: board,
    title: "Follow up visit",
    value: "vistation",
  },
  {
    image: board,
    title: "ILL health",
    value: "illHealth",
  },
];

export const ClientVetService = [
  {
    image: vet,
    title: "Dentist",
    value: "dentist",
  },
  {
    image: vet,
    title: "Optician",
    value: "optician",
  },
];

export const INSURANCE_OPTIONS = [
  {
    id: 1,
    title: "FUTURE GENERALI TOTAL INSURANCE SOLUTIONS",
    image: LGLOGO,
    premiun: 488,
    typeOfInsurance: [
      {
        title: "Standard Coverage",
        plane: [
          "Surgery and Treatment",
          "Terminal ILLness Cover",
          "Pre-hospitalization and post-hospitalization Expenses",
          "Death and Funeral",
        ],
      },
      {
        title: "Add-On Coverage",
        plane: [
          "Third-Party Liability",
          "Emergency Pet Minding",
          "Lost and Stolen Cover",
          "Vertinary Consulting and Doctor on Call",
        ],
      },
    ],
  },
];

export const INSURANCE_TYPE = [
  {
    title: "Third Party Liability Cover",
    price: 10,
    value: "Third_Party_Liability_Cover",
  },
  {
    title: "Lost and Stolen Cover",
    price: 65,
    value: "Lost_and_Stolen_Cover",
  },
  {
    title: "Emergency Pet Minding",
    price: 20,
    value: "Emergency_Pet_Minding",
  },
  {
    title: "Vet Consultation & Doctor on Call",
    price: 32,
    value: "Vet_Consultation_&_Doctor_on_Call",
  },
];

export const VACCINATIONS_PROVIDED = [
  {
    title: "Rabies",
    value: "Rabies",
  },
  {
    title: "Leptospirosis",
    value: "Leptospirosis",
  },
  {
    title: "Distemper",
    value: "Distemper",
  },
  {
    title: "Parainfluenza",
    value: "Parainfluenza",
  },
  {
    title: "Hepatitis",
    value: "Hepatitis",
  },
  {
    title: "Parvovirus",
    value: "Parvovirus",
  },
  {
    title: "Adeno virus",
    value: "Adeno_virus",
  },
  {
    title: "Corona",
    value: "Corona",
  },
];

export const PATHOLOGY_OPTIONS = [
  {
    title: "Complete Blood Count + Parasites",
    detail:
      "Complete Blood Count ( CBC : Hb, RBC, WBC, Differential WBC, PCV, Platelet count, PCV, MCV, MCH, MCHC, Cell Abnormalities) + Parasites",
    value: "Complete Blood Count + Parasites",
    price: 400,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Blood smear examination for hemoprotozoons / blood parasites",
    detail: "Blood smear examination for hemoprotozoons / blood parasites",
    value: "Blood smear examination for hemoprotozoons / blood parasites",
    price: 400,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Reticulocyte Count with retic index and absolute reticulocyte count",
    detail: "Reticulocyte Count with retic index and absolute reticulocyte count",
    value: "Reticulocyte Count with retic index and absolute reticulocyte count",
    price: 400,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Erythrocyte Sedimentation Rate ( ESR )",
    detail: "Erythrocyte Sedimentation Rate ( ESR )",
    value: "Erythrocyte Sedimentation Rate ( ESR )",
    price: 100,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Platelet Count (Machine/manual)",
    detail: "Platelet Count (Machine/manual)",
    value: "Platelet Count (Machine/manual)",
    price: 300,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Liver Function Tests (Bilirubin, SGOT, SGPT, SAP, Proteins, Albumin)",
    detail: "Liver Function Tests (Bilirubin, SGOT, SGPT, SAP, Proteins, Albumin)",
    value: "Liver Function Tests (Bilirubin, SGOT, SGPT, SAP, Proteins, Albumin)",
    price: 600,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Serum Bilirubin ( Total and Direct ) ",
    detail: "Serum Bilirubin ( Total and Direct )",
    value: "Serum Bilirubin ( Total and Direct )",
    price: 200,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Serum Glutamic Oxaloacetic Transaminase ( SGOT/AST)",
    detail: "Serum Glutamic Oxaloacetic Transaminase ( SGOT/AST)",
    value: "Serum Glutamic Oxaloacetic Transaminase ( SGOT/AST)",
    price: 200,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Serum Glutamic Pyruvic Transaminase (SGPT/ALT)",
    detail: "Serum Glutamic Pyruvic Transaminase (SGPT/ALT)",
    value: "Serum Glutamic Pyruvic Transaminase (SGPT/ALT)",
    price: 200,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Serum Alkaline Phosphatase (ALP)",
    detail: "Serum Alkaline Phosphatase (ALP)",
    value: "Serum Alkaline Phosphatase (ALP)",
    price: 200,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Serum Proteins (Total proteins, Albumin)",
    detail: "Serum Proteins (Total proteins, Albumin)",
    value: "Serum Proteins (Total proteins, Albumin)",
    price: 200,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Serum Uric Acid",
    detail: "Serum Uric Acid",
    value: "Serum Uric Acid",
    price: 300,
    isPackage: false,
    isTest: true,
  },
  {
    title: "GGT (Gamma Glutamyl Transferase )",
    detail: "GGT (Gamma Glutamyl Transferase )",
    value: "GGT (Gamma Glutamyl Transferase )",
    price: 450,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Serum Cholesterol",
    detail: "Serum Cholesterol",
    value: "Serum Cholesterol",
    price: 200,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Serum Triglycerides",
    detail: "Serum Triglycerides",
    value: "Serum Triglycerides",
    price: 200,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Creatinine Phosphokinase (CPK)",
    detail: "Creatinine Phosphokinase (CPK)",
    value: "Creatinine Phosphokinase (CPK)",
    price: 300,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Lactate Dehydrogenase (LDH)",
    detail: "Lactate Dehydrogenase (LDH)",
    value: "Lactate Dehydrogenase (LDH)",
    price: 300,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Bile Acid – Total",
    detail: "Bile Acid – Total",
    value: "Bile Acid – Total",
    price: 1800,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Blood Ammonia Level",
    detail: "Blood Ammonia Level",
    value: "Blood Ammonia Level",
    price: 900,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Serum Acid Phosphatase",
    detail: "Serum Acid Phosphatase",
    value: "Serum Acid Phosphatase",
    price: 700,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Kidney Function Tests : Blood Urea Nitrogen ( BUN ) ",
    detail: "Kidney Function Tests : Blood Urea Nitrogen ( BUN ) ",
    value: "Kidney Function Tests : Blood Urea Nitrogen ( BUN ) ",
    price: 200,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Kidney Function Tests :  Creatinine",
    detail: "Kidney Function Tests :  Creatinine",
    value: "Kidney Function Tests :  Creatinine",
    price: 200,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Serum Phosphorous/ P",
    detail: "Serum Phosphorous/ P",
    value: "Serum Phosphorous/ P",
    price: 200,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Serum Calcium/ Ca",
    detail: "Serum Calcium/ Ca",
    value: "Serum Calcium/ Ca",
    price: 200,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Serum Electrolytes ( Sodium/Na , Potassium/K, Chlorides/Cl)",
    detail: "Serum Electrolytes ( Sodium/Na , Potassium/K, Chlorides/Cl)",
    value: "Serum Electrolytes ( Sodium/Na , Potassium/K, Chlorides/Cl)",
    price: 400,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Urinalysis",
    detail: "Urinalysis",
    value: "Urinalysis",
    price: 300,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Urinary Proteins/Urine Creatinine ratio",
    detail: "Urinary Proteins/Urine Creatinine ratio",
    value: "Urinary Proteins/Urine Creatinine ratio",
    price: 500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Urinary Proteins",
    detail: "Urinary Proteins",
    value: "Urinary Proteins",
    price: 300,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Urine Creatinine",
    detail: "Urine Creatinine",
    value: "Urine Creatinine",
    price: 300,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Urine Calcium",
    detail: "Urine Calcium",
    value: "Urine Calcium",
    price: 300,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Urine Phosphorous",
    detail: "Urine Phosphorous",
    value: "Urine Phosphorous",
    price: 300,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Urine Electrolytes",
    detail: "Urine Electrolytes",
    value: "Urine Electrolytes",
    price: 500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Pancreatic function Tests : Serum Amylase",
    detail: "Pancreatic function Tests : Serum Amylase",
    value: "Pancreatic function Tests : Serum Amylase",
    price: 500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Pancreatic function Tests : Serum Lipase",
    detail: "Pancreatic function Tests : Serum Lipase",
    value: "Pancreatic function Tests : Serum Lipase",
    price: 500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Blood Sugar",
    detail: "Blood Sugar",
    value: "Blood Sugar",
    price: 200,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Canine / Feline ",
    detail: "Canine / Feline ",
    value: "Canine / Feline ",
    price: 2600,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Specific Pancreatic Lipase (CPL)",
    detail: "Specific Pancreatic Lipase (CPL)",
    value: "Specific Pancreatic Lipase (CPL)",
    price: 2600,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Fructosamine",
    detail: "Fructosamine",
    value: "Fructosamine",
    price: 1800,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Stool Examination",
    detail: "Stool Examination",
    value: "Stool Examination",
    price: 400,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Skin Scrapings Examination – for mites & dermatophytes (KOH mounts)",
    detail: "Skin Scrapings Examination – for mites & dermatophytes (KOH mounts)",
    value: "Skin Scrapings Examination – for mites & dermatophytes (KOH mounts)",
    price: 400,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Vaginal cytology for assessment of stage of estrus",
    detail: "Vaginal cytology for assessment of stage of estrus",
    value: "Vaginal cytology for assessment of stage of estrus",
    price: 400,
    isPackage: false,
    isTest: true,
  },
  {
    title:
      "Impression smear cytology (skin/ear/others): KOH mount, Giemsa, Gram & Fungal stain",
    detail:
      "Impression smear cytology (skin/ear/others): KOH mount, Giemsa, Gram & Fungal stain",
    value:
      "Impression smear cytology (skin/ear/others): KOH mount, Giemsa, Gram & Fungal stain",
    price: 900,
    isPackage: false,
    isTest: true,
  },
  {
    title:
      "Transudates / Exudates (Cell counts, Cytology, Proteins, Sugar, OBT, Rivalta)",
    detail:
      "Transudates / Exudates (Cell counts, Cytology, Proteins, Sugar, OBT, Rivalta)",
    value:
      "Transudates / Exudates (Cell counts, Cytology, Proteins, Sugar, OBT, Rivalta)",
    price: 1000,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Urinary Calculi: Chemical Analysis",
    detail: "Urinary Calculi: Chemical Analysis",
    value: "Urinary Calculi: Chemical Analysis",
    price: 1500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Urine Cortisol",
    detail: "Urine Cortisol",
    value: "Urine Cortisol",
    price: 1600,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Phenobarbitone Level",
    detail: "Phenobarbitone Level",
    value: "Phenobarbitone Level",
    price: 1600,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Serum protein Electrophoresis",
    detail: "Serum protein Electrophoresis",
    value: "Serum protein Electrophoresis",
    price: 1800,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Fine Needle Aspiration Cytology (FNAC)",
    detail: "Fine Needle Aspiration Cytology (FNAC)",
    value: "Fine Needle Aspiration Cytology (FNAC)",
    price: 800,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Histopathology (Small Tissues up to 8 cm x 8 cm x 8 cm )",
    detail: "Histopathology (Small Tissues up to 8 cm x 8 cm x 8 cm )",
    value: "Histopathology (Small Tissues up to 8 cm x 8 cm x 8 cm )",
    price: 1500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Histopathology (Medium Tissues up to 15 cm x 15 cm x 15 cm)",
    detail: "Histopathology (Medium Tissues up to 15 cm x 15 cm x 15 cm)",
    value: "Histopathology (Medium Tissues up to 15 cm x 15 cm x 15 cm)",
    price: 2000,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Histopathology (Big Tissues: More than 15 cm x 15 cm x 15 cm)",
    detail: "Histopathology (Big Tissues: More than 15 cm x 15 cm x 15 cm)",
    value: "Histopathology (Big Tissues: More than 15 cm x 15 cm x 15 cm)",
    price: 3000,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Post Mortem Histopathology (Up to 6 organs)",
    detail: "Post Mortem Histopathology (Up to 6 organs)",
    value: "Post Mortem Histopathology (Up to 6 organs)",
    price: 4000,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Post Mortem Histopathology (Up to 10 organs)",
    detail: "Post Mortem Histopathology (Up to 10 organs)",
    value: "Post Mortem Histopathology (Up to 10 organs)",
    price: 6000,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Gram’s Stain",
    detail: "Gram’s Stain",
    value: "Gram’s Stain",
    price: 700,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Acid Fast Stain",
    detail: "Acid Fast Stain",
    value: "Acid Fast Stain",
    price: 700,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Alcian blue stain",
    detail: "Alcian blue stain",
    value: "Alcian blue stain",
    price: 700,
    isPackage: false,
    isTest: true,
  },
  {
    title:
      "Bacterial Culture (aerobic) , Organism identification & Antibiotic Sensitivity",
    detail:
      "Bacterial Culture (aerobic) , Organism identification & Antibiotic Sensitivity",
    value:
      "Bacterial Culture (aerobic) , Organism identification & Antibiotic Sensitivity",
    price: 1500,
    isPackage: false,
    isTest: true,
  },
  {
    title:
      "Bacterial Culture (anaerobic) , Organism identification & Antibiotic Sensitivity",
    detail:
      "Bacterial Culture (anaerobic) , Organism identification & Antibiotic Sensitivity",
    value:
      "Bacterial Culture (anaerobic) , Organism identification & Antibiotic Sensitivity",
    price: 2000,
    isPackage: false,
    isTest: true,
  },
  {
    title:
      "Blood bacterial Culture (aerobic), Organism identification & Antibiotic Sensitivity Tests",
    detail:
      "Blood bacterial Culture (aerobic), Organism identification & Antibiotic Sensitivity Tests",
    value:
      "Blood bacterial Culture (aerobic), Organism identification & Antibiotic Sensitivity Tests",
    price: 2000,
    isPackage: false,
    isTest: true,
  },
  {
    title:
      "Blood bacterial Culture (anaerobic), Organism identification & Antibiotic Sensitivity Tests",
    detail:
      "Blood bacterial Culture (anaerobic), Organism identification & Antibiotic Sensitivity Tests",
    value:
      "Blood bacterial Culture (anaerobic), Organism identification & Antibiotic Sensitivity Tests",
    price: 2200,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Fungal Culture and Identification",
    detail: "Fungal Culture and Identification",
    value: "Fungal Culture and Identification",
    price: 1500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Fungal Culture and Identification & antibiotic sensitivity",
    detail: "Fungal Culture and Identification & antibiotic sensitivity",
    value: "Fungal Culture and Identification & antibiotic sensitivity",
    price: 2200,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Insulin",
    detail: "Insulin",
    value: "Insulin",
    price: 900,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Estrogen",
    detail: "Estrogen",
    value: "Estrogen",
    price: 900,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Progesterone",
    detail: "Progesterone",
    value: "Progesterone",
    price: 900,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Testosterone",
    detail: "Testosterone",
    value: "Testosterone",
    price: 900,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Cortisol",
    detail: "Cortisol",
    value: "Cortisol",
    price: 900,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Thyroid Function (T3, T4, TSH)",
    detail: "Thyroid Function (T3, T4, TSH)",
    value: "Thyroid Function (T3, T4, TSH)",
    price: 800,
    isPackage: false,
    isTest: true,
  },
  {
    title: "T3",
    detail: "T3",
    value: "T3",
    price: 450,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Free T3",
    detail: "Free T3",
    value: "Free T3",
    price: 450,
    isPackage: false,
    isTest: true,
  },
  {
    title: "T4",
    detail: "T4",
    value: "T4",
    price: 450,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Free T4",
    detail: "Free T4",
    value: "Free T4",
    price: 450,
    isPackage: false,
    isTest: true,
  },
  {
    title: "TSH",
    detail: "TSH",
    value: "TSH",
    price: 450,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Parathyroid Hormone",
    detail: "Parathyroid Hormone",
    value: "Parathyroid Hormone",
    price: 1800,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Prothrombin time (PT)",
    detail: "Prothrombin time (PT)",
    value: "Prothrombin time (PT)",
    price: 500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Activated partial Thromboplastin Time (APTT)",
    detail: "Activated partial Thromboplastin Time (APTT)",
    value: "Activated partial Thromboplastin Time (APTT)",
    price: 500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Fibrinogen",
    detail: "Fibrinogen",
    value: "Fibrinogen",
    price: 1200,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Fibrinogen Degradation Products (FDP)",
    detail: "Fibrinogen Degradation Products (FDP)",
    value: "Fibrinogen Degradation Products (FDP)",
    price: 1600,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Autoa gglutination studies at three different temperature levels",
    detail: "Autoa gglutination studies at three different temperature levels",
    value: "Autoa gglutination studies at three different temperature levels",
    price: 1000,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Blood Cross Match : Major and Minor at variable temperatures",
    detail: "Blood Cross Match : Major and Minor at variable temperatures",
    value: "Blood Cross Match : Major and Minor at variable temperatures",
    price: 1800,
    isPackage: false,
    isTest: true,
  },
  {
    title: "SNAP 4 DX (Ehrlichia, Borrehlia, Anaplasma & Heartworm )",
    detail: "SNAP 4 DX (Ehrlichia, Borrehlia, Anaplasma & Heartworm )",
    value: "SNAP 4 DX (Ehrlichia, Borrehlia, Anaplasma & Heartworm )",
    price: 1500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "SNAP 3 DX (Ehrlichia, Babesi gibsoni, Anaplasma )",
    detail: "SNAP 3 DX (Ehrlichia, Babesi gibsoni, Anaplasma )",
    value: "SNAP 3 DX (Ehrlichia, Babesi gibsoni, Anaplasma )",
    price: 1800,
    isPackage: false,
    isTest: true,
  },
  {
    title: "SNAP test for detection of Canine Distemper infection",
    detail: "SNAP test for detection of Canine Distemper infection",
    value: "SNAP test for detection of Canine Distemper infection",
    price: 1300,
    isPackage: false,
    isTest: true,
  },
  {
    title: "SNAP test for detection of Canine Parvovirus infection",
    detail: "SNAP test for detection of Canine Parvovirus infection",
    value: "SNAP test for detection of Canine Parvovirus infection",
    price: 1300,
    isPackage: false,
    isTest: true,
  },
  {
    title: "SNAP test for detection of Feline Panleukopenia / Parvovirus infection",
    detail: "SNAP test for detection of Feline Panleukopenia / Parvovirus infection",
    value: "SNAP test for detection of Feline Panleukopenia / Parvovirus infection",
    price: 1800,
    isPackage: false,
    isTest: true,
  },
  {
    title: "SNAP test for detection of Feline Immunodeficiency Virus infection",
    detail: "SNAP test for detection of Feline Immunodeficiency Virus infection",
    value: "SNAP test for detection of Feline Immunodeficiency Virus infection",
    price: 1500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "SNAP test for detection of Feline Leukaemia virus infection",
    detail: "SNAP test for detection of Feline Leukaemia virus infection",
    value: "SNAP test for detection of Feline Leukaemia virus infection",
    price: 1500,
    isPackage: false,
    isTest: true,
  },
  {
    title:
      "SNAP test for detection of Feline Immunodeficiency Virus & Feline Leukaemia virus infection (Combination test)",
    detail:
      "SNAP test for detection of Feline Immunodeficiency Virus & Feline Leukaemia virus infection (Combination test)",
    value:
      "SNAP test for detection of Feline Immunodeficiency Virus & Feline Leukaemia virus infection (Combination test)",
    price: 2400,
    isPackage: false,
    isTest: true,
  },
  {
    title: "SNAP test for detection of Toxoplasma antibodies",
    detail: "SNAP test for detection of Toxoplasma antibodies",
    value: "SNAP test for detection of Toxoplasma antibodies",
    price: 1300,
    isPackage: false,
    isTest: true,
  },

  {
    title: "PCR diagnosis of Bartonella",
    detail: "PCR diagnosis of Bartonella",
    value: "PCR diagnosis of Bartonella",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of Tick born diseases: Babesia gibsoni",
    detail: "PCR diagnosis of Tick born diseases: Babesia gibsoni",
    value: "PCR diagnosis of Tick born diseases: Babesia gibsoni",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of Tick born diseases: Babesia canis",
    detail: "PCR diagnosis of Tick born diseases: Babesia canis",
    value: "PCR diagnosis of Tick born diseases: Babesia canis",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of Tick born diseases:Ehrlichia canis",
    detail: "PCR diagnosis of Tick born diseases:Ehrlichia canis",
    value: "PCR diagnosis of Tick born diseases:Ehrlichia canis",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of Tick born diseases:Hepatozoon canis",
    detail: "PCR diagnosis of Tick born diseases:Hepatozoon canis",
    value: "PCR diagnosis of Tick born diseases:Hepatozoon canis",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of Tick born diseases:Anaplasma",
    detail: "PCR diagnosis of Tick born diseases:Anaplasma",
    value: "PCR diagnosis of Tick born diseases:Anaplasma",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of feline diseases: Feline Panleukopenia",
    detail: "PCR diagnosis of feline diseases: Feline Panleukopenia",
    value: "PCR diagnosis of feline diseases: Feline Panleukopenia",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of feline diseases: Feline Leukaemia virus",
    detail: "PCR diagnosis of feline diseases: Feline Leukaemia virus",
    value: "PCR diagnosis of feline diseases: Feline Leukaemia virus",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of feline diseases: Feline Immunodeficiency Virus",
    detail: "PCR diagnosis of feline diseases: Feline Immunodeficiency Virus",
    value: "PCR diagnosis of feline diseases: Feline Immunodeficiency Virus",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of feline diseases: Feline Infectious Peritonitis",
    detail: "PCR diagnosis of feline diseases: Feline Infectious Peritonitis",
    value: "PCR diagnosis of feline diseases: Feline Infectious Peritonitis",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of feline diseases: Feline Coronavirus",
    detail: "PCR diagnosis of feline diseases: Feline Coronavirus",
    value: "PCR diagnosis of feline diseases: Feline Coronavirus",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of feline diseases: Feline Herpes Virus",
    detail: "PCR diagnosis of feline diseases: Feline Herpes Virus",
    value: "PCR diagnosis of feline diseases: Feline Herpes Virus",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of feline diseases: Toxoplasma",
    detail: "PCR diagnosis of feline diseases: Toxoplasma",
    value: "PCR diagnosis of feline diseases: Toxoplasma",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of feline diseases: Mycoplasma",
    detail: "PCR diagnosis of feline diseases: Mycoplasma",
    value: "PCR diagnosis of feline diseases: Mycoplasma",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of feline diseases: Chlamydia felis",
    detail: "PCR diagnosis of feline diseases: Chlamydia felis",
    value: "PCR diagnosis of feline diseases: Chlamydia felis",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of feline diseases: Hemobartonella",
    detail: "PCR diagnosis of feline diseases: Hemobartonella",
    value: "PCR diagnosis of feline diseases: Hemobartonella",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of feline diseases: Calcivirus",
    detail: "PCR diagnosis of feline diseases: Calcivirus",
    value: "PCR diagnosis of feline diseases: Calcivirus",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Toxoplasma IgM & IgG: Quantitative",
    detail: "Toxoplasma IgM & IgG: Quantitative",
    value: "Toxoplasma IgM & IgG: Quantitative",
    price: 900,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Leptospira IgM : Quantitative",
    detail: "Leptospira IgM : Quantitative",
    value: "Leptospira IgM : Quantitative",
    price: 1600,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Leptospira IgG : Quantitative",
    detail: "Leptospira IgG : Quantitative",
    value: "Leptospira IgG : Quantitative",
    price: 1600,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis for Leptospira sp.",
    detail: "PCR diagnosis for Leptospira sp.",
    value: "PCR diagnosis for Leptospira sp.",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis for Toxoplasma sp",
    detail: "PCR diagnosis for  Toxoplasma sp",
    value: "PCR diagnosis for  Toxoplasma sp",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Serum Iron Studies ( Iron + Transferin saturation + TIBC)",
    detail: "Serum Iron Studies ( Iron + Transferin saturation + TIBC)",
    value: "Serum Iron Studies ( Iron + Transferin saturation + TIBC)",
    price: 900,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Serum Magnesium",
    detail: "Serum Magnesium",
    value: "Serum Magnesium",
    price: 700,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Serum Biocarbonates",
    detail: "Serum Biocarbonates",
    value: "Serum Biocarbonates",
    price: 700,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Zinc",
    detail: "Zinc",
    value: "Zinc",
    price: 2600,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Lead",
    detail: "Lead",
    value: "Lead",
    price: 2600,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Vitamin D3 (25 Hydroxy)",
    detail: "Vitamin D3 (25 Hydroxy)",
    value: "Vitamin D3 (25 Hydroxy)",
    price: 1600,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Vitamin B9 ( Folic Acid)",
    detail: "Vitamin B9 ( Folic Acid)",
    value: "Vitamin B9 ( Folic Acid)",
    price: 1600,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Vitamin B12 (Cyanocobalamine)",
    detail: "Vitamin B12 (Cyanocobalamine)",
    value: "Vitamin B12 (Cyanocobalamine)",
    price: 1600,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Vitamin B1 (Thiamin)",
    detail: "Vitamin B1 (Thiamin)",
    value: "Vitamin B1 (Thiamin)",
    price: 3800,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Vitamin B2( Riboflavin)",
    detail: "Vitamin B2( Riboflavin)",
    value: "Vitamin B2( Riboflavin)",
    price: 3800,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Vitamin B6 ( Pyridoxine)",
    detail: "Vitamin B6 ( Pyridoxine)",
    value: "Vitamin B6 ( Pyridoxine)",
    price: 3800,
    isPackage: false,
    isTest: true,
  },

  {
    title: "PCR diagnosis of Canine Distemper",
    detail: "PCR diagnosis of Canine Distemper",
    value: "PCR diagnosis of Canine Distemper",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of Parvovirus",
    detail: "PCR diagnosis of Parvovirus",
    value: "PCR diagnosis of Parvovirus",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of Brucellosis",
    detail: "PCR diagnosis of Brucellosis",
    value: "PCR diagnosis of Brucellosis",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of Giardia",
    detail: "PCR diagnosis of Giardia",
    value: "PCR diagnosis of Giardia",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of Heartworm",
    detail: "PCR diagnosis of Heartworm",
    value: "PCR diagnosis of Heartworm",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of  Bartonella",
    detail: "PCR diagnosis of  Bartonella",
    value: "PCR diagnosis of  Bartonella",
    price: 4500,
    isPackage: true,
    isTest: false,
  },
];

export const PATHOLOGY_PACKAGES = [
  {
    title: "Reno basic",
    detail:
      "Complete Blood Count ( CBC : Hb, RBC, WBC, Differential WBC, PCV, Platelet count, PCV, MCV, MCH, MCHC, Cell Abnormalities)",
    value: "Reno basic",
    price: 1600,
    isPackage: true,
    isTest: false,
  },
  {
    title: "Reno + Card",
    detail:
      "Complete Blood Count ( CBC : Hb, RBC, WBC, Differential WBC, PCV, Platelet count, PCV, MCV, MCH, MCHC, Cell Abnormalities)",
    value: "Reno + Card",
    price: 2500,
    isPackage: true,
    isTest: false,
  },
  {
    title: "Comprehensive",
    detail:
      "Complete Blood Count ( CBC : Hb, RBC, WBC, Differential WBC, PCV, Platelet count, PCV, MCV, MCH, MCHC, Cell Abnormalities)",
    value: "Comprehensive",
    price: 3500,
    isPackage: true,
    isTest: false,
  },
  {
    title: "Basic",
    detail:
      "Complete Blood Count ( CBC : Hb, RBC, WBC, Differential WBC, PCV, Platelet count, PCV, MCV, MCH, MCHC, Cell Abnormalities)",
    value: "Basic",
    price: 1000,
    isPackage: true,
    isTest: false,
  },
  {
    title: "Primary profile",
    detail:
      "Complete Blood Count ( CBC : Hb, RBC, WBC, Differential WBC, PCV, Platelet count, PCV, MCV, MCH, MCHC, Cell Abnormalities)",
    value: "Primary profile",
    price: 800,
    isPackage: true,
    isTest: false,
  },
  {
    title: "Thyroid profile",
    detail: "Thyroid Function Profile( T3, T4, TSH, Free T3, Free T4)",
    value: "Thyroid profile",
    price: 1650,
    isPackage: true,
    isTest: false,
  },
  {
    title: "Basic + Thyro",
    detail: "Thyroid Function Profile( T3, T4, TSH, Free T3, Free T4)",
    value: "Basic + Thyro",
    price: 1500,
    isPackage: true,
    isTest: false,
  },
  {
    title: "Comprehensive + Thyro",
    detail:
      "Complete Blood Count ( CBC : Hb, RBC, WBC, Differential WBC, PCV,Platelet count, PCV, MCV, MCH, MCHC, Cell Abnormalities)",
    value: "Comprehensive + Thyro",
    price: 4000,
    isPackage: true,
    isTest: false,
  },
  {
    title: "PCR diagnosis of Tick born diseases:",
    detail:
      "Combo Test inclusive of Babesia gibsoni, Babesia canis, Babesia vogeli, Babesia rossi, Ehrlichia canis, Hepatozoon canis, Anaplasma sp. , Trypanosoma evansi",
    value: "PCR diagnosis of Tick born diseases:",
    price: 12500,
    isPackage: true,
    isTest: false,
  },

  {
    title: "PCR diagnosis of feline diseases:",
    detail:
      "Combo Test inclusive of Feline Panleukopenia, Feline Leukaemia virus, Feline Immunodeficiency Virus, Feline Infectious Peritonitis, Feline Coronavirus, Feline Herpes Virus, Toxoplasma, Mycoplasma, Chlamydia felis,Hemobartonella, Calcivirus,",
    value: "PCR diagnosis of feline diseases: ",
    price: 12500,
    isPackage: true,
    isTest: false,
  },
  {
    title: "Coagulation profile inclusive of",
    detail:
      "Prothrombin time (PT), Activated partial Thromboplastin Time (APTT), Fibrinogen, Fibrinogen Degradation Products (FDP)",
    value: "Coagulation profile inclusive of",
    price: 3200,
    isPackage: true,
    isTest: false,
  },
  {
    title: "Mineral profile:",
    detail:
      "Aluminium, Antimony, Arsenic, Baarium, Beryllium Bismuth, Cadmium, Caesium Chromium, Cobalt, Lead, Molybdenum, Manganese, Mercury, Nickel, Selenium, Silver, Strontium,, Thallium, TIN, Uranium, Vanadium,",
    value: "Mineral profile:",
    price: 8500,
    isPackage: false,
    isTest: true,
  },
];

export const GROOMING_OPTIONS = [
  {
    image: Paw,
    title: "Paw Hair Trimming",
    value: "pawTriming",
  },
  {
    image: EarCleaning,
    title: "Ear Cleaning",
    value: "earCleaning",
  },
  {
    image: EarCleaning,
    title: "Ear Hair Trimming",
    value: "earHairTriming",
  },
  {
    image: Nails,
    title: "Nail Trimming",
    value: "nailTrimming",
  },
];

export const profileOptions = [
  {
    image: Casper,
    title: "Casper",
    subtitle: "Pug | 3 year Old",
    gender: "male",
  },
  {
    image: Rosy,
    title: "Rosy",
    subtitle: "Persian | 5 year Old",
    gender: "male",
  },
];

export const TimeOptions = [
  {
    label: "07:00 am",
    value: 7,
    availability: true,
  },
  {
    label: "08:00 am",
    value: 8,
    availability: true,
  },
  {
    label: "09:00 am",
    value: 9,
    availability: true,
  },
  {
    label: "10:00 am",
    value: 10,
    availability: true,
  },
  {
    label: "11:00 am",
    value: 11,
    availability: true,
  },
  {
    label: "12:00 pm",
    value: 12,
    availability: true,
  },
  {
    label: "01:00 pm",
    value: 13,
    availability: true,
  },
  {
    label: "02:00 pm",
    value: 14,
    availability: true,
  },
  {
    label: "03:00 pm",
    value: 15,
    availability: true,
  },
  {
    label: "04:00 pm",
    value: 16,
    availability: true,
  },
  {
    label: "05:00 pm",
    value: 17,
    availability: true,
  },
  {
    label: "06:00 pm",
    value: 18,
    availability: true,
  },
  {
    label: "07:00 pm",
    value: 19,
    availability: true,
  },
  {
    label: "08:00 pm",
    value: 20,
    availability: true,
  },
  {
    label: "09:00 pm",
    value: 21,
    availability: true,
  },
  {
    label: "10:00 pm",
    value: 22,
    availability: true,
  },
  // {
  //   label: '11:00 pm',
  //   value: 23,
  //   availability: true,
  // },
];

export const TRAINING_TYPE = [
  { value: "reTraining", label: "Retraining" },
  { value: "behavioralTraining", label: "Special Behavioural" },
  { value: "obidienceTraining", label: "Obedience" },
];

export const SESSIONS = [
  { value: "Three", label: "1 month (12 Sessions)" },
  { value: "Six", label: "2 months (24 Sessions)" },
  { value: "Twelve", label: "3 months (36 Sessions)" },
  { value: "Forty-eight", label: "4 months (48 Sessions)" },
];

export const SESSIONS_OLDER = [
  { value: "Three", label: "3 Sessions" },
  { value: "Six", label: "6 Sessions" },
  { value: "Nine", label: "9 Sessions" },
  { value: "Twelve", label: "12 Sessions" },
  { value: "Twenty-four", label: "24 Sessions" },
  { value: "Thirty-six", label: "36 Sessions" },
  { value: "Forty-eight", label: "48 Sessions" },
];

export const WEIGHT_OPTIONS = [
  { value: "kg_0_1", label: "0 - 1 Kg" },
  { value: "kg_1_3", label: "1 - 3 Kg" },
  { value: "kg_3_5", label: "3 - 5 Kg" },
  { value: "kg_5_10", label: "5 - 10 Kg" },
  { value: "kg_10_20", label: "10 - 20 Kg" },
  { value: "kg_20_40", label: "20 - 40 Kg" },
  { value: "kg_40", label: "40+ Kg" },
];
export const AGE_OPTIONS = [...Array(50).keys()].map((i) => ({
  value: i,
  label: `${i} year`,
}));
export const MONTH_OPTIONS = [...Array(13).keys()].map((i) => ({
  value: i,
  label: `${i} month`,
}));

export const statusData = [
  {
    title: "All",
    value: "All",
  },

  {
    title: "Job completed",
    value: "Completed",
  },

  {
    title: "Cancelled by you",
    value: "canceled_by_seeker",
  },

  {
    title: "Cancelled by expert",
    value: "canceled_by_provider",
  },
  {
    title: "No show by expert",
    value: "no_show_provider",
  },
  {
    title: "No show by me",
    value: "no_show_seeker",
  },

  {
    title: "Expired",
    value: "Expired",
  },
];

export const SERVICE_STATUS = {
  accepting_offers: "Accepting Offers",
  max_offers_received: "Max Offers Received",
  job_completed: "Job Completed",
  job_started: "Job Started",
  offer_accepted: "Booked",
  time_expired: "Expired",
  offer_time_expired: "Expired",
  request_expired: "Expired",
  canceled_by_seeker: "Cancelled By Me",
  canceled_by_provider: "Cancelled By Expert",
  no_show_seeker: "No Show By Me",
  no_show_provider: "No Show By Expert",
};

export const serviceData = [
  {
    title: "pet Insurance",
    value: "pet_insurance",
  },
  {
    title: "Veterinarian",
    value: "vet",
  },

  {
    title: "Dog walking",
    value: "dog_walking",
  },

  {
    title: "Trainer",
    value: "pet_trainers",
  },

  {
    title: "Grooming",
    value: "pet_grooming",
  },

  {
    title: "Sitting",
    value: "pet_sitters",
  },

  {
    title: "Boarding",
    value: "pet_boarders",
  },
];

export const DATE_OPTIONS = [
  { label: "Newest to Oldest", value: "asce" },
  { label: "Oldest to Newest", value: "dsce" },
];

export const REQ_PET_CARD_OPTIONS = [
  {
    image: Casper,
    name: "Casper",
    subText: "Pug  I  3 Year 5 Month",
    status: "No offer recieved!",
    type: "Grooming",
  },
  {
    image: Casper,
    name: "Casper",
    subText: "Pug  I  3 Year 5 Month",
    status: "Booked",
    type: "Grooming",
  },
  {
    image: Casper,
    name: "Casper",
    subText: "Pug  I  3 Year 5 Month",
    status: "2 Request recieved",
    type: "Grooming",
  },
  {
    image: Casper,
    name: "Casper",
    subText: "Pug  I  3 Year 5 Month",
    status: "Booked",
    type: "Grooming",
  },
];

export const REQ_PROFILE_CARD_OPTIONS = [
  {
    id: 1,
    image: Person1,
    name: "Sunita",
    rate: "₹ 1500",
    rating: "4.5",
    timeAgo: "3 hours ago",
    timeLeft: "1 hour 12 min",
    aboutMe:
      "I grew up with dogs. They give you happiness and they make you smile and laugh. I love dogs! We have a fenced backyard and I love to go for walks. Dogs are awesome and they should live a happy life.",
    images: [cat1, cat2, cat3, dog1, dog2, dog3],
    certificates: [cert1, cert2, cert3, cert4],
    experiences: "3 years",
    otherServices: ["Training", "Boarding"],
    awards: [cert1, cert2, cert3, cert4],
  },
  {
    id: 2,
    image: Person2,
    name: "Rajesh kumar",
    rate: "₹ 1500",
    rating: "4.5",
    timeAgo: "3 hours ago",
    timeLeft: "1 hour 12 min",
    aboutMe:
      "I grew up with dogs. They give you happiness and they make you smile and laugh. I love dogs! We have a fenced backyard and I love to go for walks. Dogs are awesome and they should live a happy life.",
    images: [cat1, cat2, cat3, dog1, dog2, dog3],
    certificates: [cert1, cert2, cert3, cert4],
    experiences: "3 years",
    otherServices: ["Training", "Boarding"],
    awards: [cert1, cert2, cert3, cert4],
  },
  {
    id: 3,
    image: Person1,
    name: "Sunita",
    rate: "₹ 1500",
    rating: "4.5",
    timeAgo: "3 hours ago",
    timeLeft: "1 hour 12 min",
    aboutMe:
      "I grew up with dogs. They give you happiness and they make you smile and laugh. I love dogs! We have a fenced backyard and I love to go for walks. Dogs are awesome and they should live a happy life.",
    images: [cat1, cat2, cat3, dog1, dog2, dog3],
    certificates: [cert1, cert2, cert3, cert4],
    experiences: "3 years",
    otherServices: ["Training", "Boarding"],
    awards: [cert1, cert2, cert3, cert4],
  },
];

export const WALK_OPTIONS = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
];

export const WALK_DURATION = [
  { value: "thirty", label: "30 min" },
  { value: "fortyFive", label: "45 Min" },
  { value: "oneHour", label: "1 Hour" },
];

export const BATHING_OPTIONS = [
  { value: "hygieneBath", label: "Hygiene bath" },
  { value: "tickTreatmentBath", label: "Tick Treatment bath" },
  { value: "miteTreatmentBath", label: "Mite treatment bath" },
];

export const HAIR_STYLE_OPTIONS = [
  { value: "zeroTriming", label: "Zero Trimming" },
  { value: "trimFullBodyHair", label: "Trim Full Body Hair" },
  { value: "hairStyling", label: "Hair Styling" },
];

export const DATE_TYPE_OPTIONS = [
  { value: 1, label: "All days of the week" },
  {
    value: 2,
    label: "Working days of the week",
  },
  { value: 3, label: "Weekend days" },
];

export const SERVICE_CONSTANTS = {
  business_start_time: "09:00:00",

  business_end_time: "18:00:00",

  make_offer_expiry_hours: "240",

  accept_offer_expiry_hours: "600",

  send_soft_notificatons: "30",

  minDateForRequest: 2,

  maxDateForRequest: 8,

  max_offers_alowed: 3,

  otpexpirytime: 10,

  bookingAmountPercentage: 10,

  charges: 0.1,
  cancel_request_cutoff_time: 30,

  trending_expert_limit: 10,

  jobs_completed_limit: 10,

  ongoing_job_limit: 2,

  time_to_start_job: 30,
};

export const authLinks = [
  { title: "About us", link: "https://pawpurrfect.co/stage/about-us/" },
  { title: "Who back us", link: "https://pawpurrfect.co/stage/who-backs-us/" },
  { title: "Services", link: "https://pawpurrfect.co/stage/services/" },
  { title: "why us", link: "https://pawpurrfect.co/stage/why-us/" },
  {
    title: "How to book?",
    link: "/",
    listElements: [
      {
        title: "For Pet Parents",
        link: "https://pawpurrfect.co/stage/for-pet-parents/",
      },
      {
        title: "For Service Experts",
        link: "https://pawpurrfect.co/stage/for-service-experts/",
      },
    ],
  },
  { title: "Testimonials", link: "https://pawpurrfect.co/stage/testimonial/" },
  {
    title: "FAQ'S",
    link: "/",
    listElements: [
      {
        title: "Pet Parents",
        link: "https://pawpurrfect.co/stage/pet-parents-faqs/",
      },
      {
        title: "Service Experts",
        link: "https://pawpurrfect.co/stage/service-experts-faqs/",
      },
    ],
  },
  { title: "Blogs", link: "https://pawpurrfect.co/stage/blog/" },
  { title: "Connect", link: "https://pawpurrfect.co/stage/connect/" },
];

export const userLinks = [
  { title: "Dashboard", link: "/" },
  // { title: "My Requests", link: "/my-requests" },
  // { title: "My Pet Profiles", link: "/my-pet-profile" },
];

export const jobDetailTitle = {
  constOfSessions: "Cost Of Sessions",
  meetAndGreet: "Meet and Greet",
  thirty: "Thirty Minute Dog Walk",
  fortyFive: "Forty-five Minute Dog Walk",
  oneHour: "One Hour Dog Walk",
  oneDay: "One Day",
  twoHours: "Two hours Dog Walk",
  twelveHours: "Twelve hours",
  behavioralTrainingThree: "Behavioral Training Three",
  obidienceTrainingThree: "Obidience Training Three",
  firstTrainingTwelve: "First Training Twelve",
  behavioralTrainingNine: "Behavioral Training Nine",
  obidienceTrainingSix: "Obidience Training Six",
};
