import "animate.css";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { MdRemoveRedEye } from "react-icons/md";
import { AiFillCloseCircle } from "react-icons/ai";
import { TiTick } from "react-icons/ti";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { markNotificationAsSeen } from "../Redux/actions/notificationsActions";
import Avatar from "./Avatar";

const NotificationCard = ({ small, animate, item }) => {
  // useEffect(() => {
  //   new WOW.WOW().init();
  // }, []);
  const { title, body, created_at, seen, request_id } = item;
  const dispatch = useDispatch();

  let tempTime = new Date(created_at);
  const TIME_AGO = moment(tempTime).fromNow();

  const navigate = useNavigate();

  const navigateToReqDetails = () => {
    console.log("navigate to req details");
    if (request_id) {
      // console.log('request_id', request_id)
      navigate(`/req-details/${request_id}/${0}`);
      if (!item.seen) {
        dispatch(markNotificationAsSeen(item));
      }
    }
  };

  return (
    <div
      // onClick={navigateToReqDetails}
      className={`border-1 my-2 rounded-md p-2 flex flex-row items-start ${
        animate && "wow animate__animated animate__fadeInUp animate__delay-0.4s"
      } ${seen && "bg-slate-200"}`}
    >
      <Avatar letter={title?.charAt(0)} />
      <div
        onClick={navigateToReqDetails}
        className="ml-2 cursor-pointer"
        style={{ flexGrow: 1, overflow: "hidden" }}
      >
        <p className="text-sm font-medium">{title}</p>
        <p className="text-xs text-light">{TIME_AGO}</p>
        <p className={`text-sm font-number font-medium  ${small && "single-line"}`}>
          {body}
        </p>
      </div>
      {!seen && (
        <div className="flex my-4">
          <MdRemoveRedEye
            className="border-2 mt-auto mb-auto border-white border-solid rounded-full cursor-pointer"
            onClick={() => {
              navigate("/notifications");
              dispatch(markNotificationAsSeen(item));
            }}
            size="24"
            fill="#F49F02"
          />

          <AiFillCloseCircle
            className="border-2 mt-auto mb-auto border-white bg-white border-solid rounded-full cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              dispatch(markNotificationAsSeen(item));
            }}
            size="24"
            fill="#F49F02"
          />
        </div>
      )}
      {seen && (
        <TiTick
          className="border-2 mt-auto mb-auto rounded-full cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(markNotificationAsSeen(item));
          }}
          size="24"
          fill="#79d1bb"
        />
      )}
    </div>
  );
};

NotificationCard.defaultProps = {
  small: false,
  animate: false,
  item: {},
};

NotificationCard.propTypes = {
  small: PropTypes.bool,
  animate: PropTypes.bool,
  item: PropTypes.object.isRequired,
};

export default NotificationCard;
