import React from 'react'
import { Provider } from 'react-redux'
import store from './Redux/store'
import Navigator from './Routes/Navigator'
import axios from 'axios'
import './Assets/App.css'
import { Provider as PV, ErrorBoundary } from '@rollbar/react'

const App = () => {
  axios.defaults.headers.common['Authorization'] = 'AUTH TOKEN'
  axios.defaults.headers.post['Content-Type'] = 'application/json'
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

  const rollbarConfig = { accessToken: 'e1734afa8a3c43c9b90ecc58bb35d030', environment: 'testenv' }

  return (
    <Provider store={store}>
      <PV config={rollbarConfig}>
        <ErrorBoundary>
          <Navigator />
        </ErrorBoundary>
      </PV>
    </Provider>
  )
}

export default App
