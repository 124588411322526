import { getAuth } from "firebase/auth";
import {
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db, notificationsRef } from "../../firebase";
import { NOTIFICATION } from "../types";

const LIMIT = 16;

export const fetchMoreNotification = () => async (dispatch, getState) => {
  try {
    const auth = getAuth();
    const {
      notification: { items },
    } = getState();
    const last = items[items.length - 1];

    const q = query(
      notificationsRef,
      where("userId", "==", auth.currentUser.uid),
      where("created_at", "<", last.created_at),
      orderBy("created_at", "desc"),
      limit(LIMIT)
    );
    const querySnapshot = await getDocs(q);
    const newItems = [];
    querySnapshot.forEach((doc) => {
      newItems.push(doc.data());
    });
    dispatch({
      type: NOTIFICATION.FETCH_SUCCESS,
      payload: {
        items: [...items, ...newItems],
        hasMore: newItems.length === LIMIT,
      },
    });
  } catch (error) {
    console.log(error.message);
  }
};

export const fetchUserNotifications = () => async (dispatch) => {
  try {
    const auth = getAuth();

    const q = query(
      notificationsRef,
      where("userId", "==", auth.currentUser.uid),
      orderBy("created_at", "desc"),
      limit(LIMIT)
    );
    const querySnapshot = await getDocs(q);
    const items = [];
    querySnapshot.forEach((doc) => {
      items.push(doc.data());
    });

    dispatch({
      type: NOTIFICATION.FETCH_SUCCESS,
      payload: { items, hasMore: items.length === LIMIT },
    });
    // Check for image tag
  } catch (error) {
    dispatch({ type: NOTIFICATION.FETCH_FAILED });
    console.warn(error);
    console.warn(error.message);
  }
};

export const markNotificationAsSeen =
  ({ docid }) =>
  async (dispatch) => {
    try {
      dispatch({ type: NOTIFICATION.MARK_AS_READ, payload: docid });
      const docRef = doc(db, "notifications", docid);
      await updateDoc(docRef, { seen: true });
    } catch (error) {
      console.log(error.message);
    }
  };
