import React, { useEffect, useState } from 'react'
import { Container, GradientButton } from '../Components'
import Insurance from '../Assets/icons/Insurance.png'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import BeatLoader from 'react-spinners/BeatLoader'

const override = {
  display: 'block',
  // margin: "0 auto",
  borderColor: 'red',
}

const CongratsScreen = () => {
  const { txnId } = useParams()
  const [insurance, setInsurance] = useState({})
  const [loading, setLoading] = useState(true)
  console.log(txnId)
  useEffect(() => {
    axios
      .post(
        'https://us-central1-pawpurrfect-stage.cloudfunctions.net/getInsuranceWithTxnid',
        {
          txnid: txnId,
        },
      )
      .then((res) => {
        setInsurance(res.data)
         setLoading(false)
        console.log(res.data)
      })
      .catch((e) => console.log(e))
  }, [txnId])
  return (
    <>
      <Container className="my-16">
        <div className="grid grid-cols-1 text-center items-center">
          <div className="flex justify-center mb-7">
            <img src={Insurance} className="h-24" />
          </div>
          <div>
            <h1 className="text-lg font-semibold">
              {/* Your request for {location.state.service} has been placed! */}
              Your Pet is now insured !
            </h1>
          </div>
          <div className="grid grid-cols-4">
            <div></div>
            <div className="col-span-2 mt-4 text-center">
              <p>
                {/* We are checking with highly rated experts around your area. You
                will get their response in the next 4-5 hours in the
                notification section of the website. */}
                Thank you. We look forward to continuing to help you look after
                your beloved pet
              </p>
            </div>
            <div></div>
          </div>
          <div className="grid grid-cols-4">
            <div></div>
            <div className="col-span-2 mt-4 text-center bg-lightYellow p-2 rounded">
              {/* <p>*Please accept one of the responses to confirm booking.</p> */}
              <p>Policy will be issued in next 48 hours.</p>
            </div>
            <div></div>
          </div>
          <div className="grid grid-cols-4">
            <div></div>
            <div className="col-span-2 mt-4 text-center">
              <p>
                {/* We are checking with highly rated experts around your area. You
                will get their response in the next 4-5 hours in the
                notification section of the website. */}
                Please save the below WIN number for any further queries
                <br />  regarding your policy
                <br />
                {loading ? (
                  <BeatLoader
                    color={'black'}
                    loading={loading}
                    cssOverride={override}
                    size={15}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                ) : (
                  <p className="text-[#b53427] font-roboto mt-1">{insurance?.WinNo}</p>
                )}
              </p>
            </div>
            <div></div>
          </div>
          <div className="grid grid-cols-4 mt-10">
            <div></div>
            <div className="col-span-2 mt-4 text-center rounded">
              <GradientButton
                title="Go To PawPurrfect"
                onPress={() => {
                  // navigate to google.com
                  window.open('https://app.pawpurrfect.co/', '_self')
                  // window.close();
                }}
              />
            </div>
            <div></div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default CongratsScreen
