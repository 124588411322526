import React from "react";
import PropTypes from "prop-types";


const FileInput = ({ displayImage, file, onChange, placeholder, disable }) => {
  return (
    <div className=" mb:mt-4 lg:mt-0 border-2 border-solid rounded-md cursor-pointer items-center h-[193px] relative overflow-hidden w-[224px] p-4">
      <p className="text-[14px] font-roboto text-center">
        {placeholder}
        <label className="text-red-800 ml-1">*</label>
      </p>
      <input
        type="file"
        onChange={onChange}
        style={{ opacity: 0, zIndex: 10 }}
        accept="image/*"
        className="w-full h-full absolute top"
        disabled={disable}
        
      />

      <div className=" h-full  p-4">
        {file && (
          <img
            src={file?.name ? URL.createObjectURL(file) : file}
            alt="upload_file"
            className="h-full w-full   object-cover  "
          />
        )}
        {!file && (
          <div className="w-full h-full flex justify-center items-center ">
            <img src={displayImage} alt="upload_file" className=" mt-2 object-cover " />
          </div>
        )}
      </div>
    </div>
  );
};

FileInput.defaultProps = {
  file: "",
  onChange: () => {},
  placeholder: "",
  disable: false,
};
FileInput.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  disable: PropTypes.bool,
};

export default FileInput;
