import { doc, getDoc } from 'firebase/firestore';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Container, GradientButton, Modal, RejectPopUp } from '..';
import groom from '../../Assets/icons/groom.png';
import vet from '../../Assets/icons/vet.png';
import train from '../../Assets/icons/train.png';
import board from '../../Assets/icons/board.png';
import dogwalk from '../../Assets/icons/dogwalk.png';
import sit from '../../Assets/icons/sit.png';
import { SERVICES_TEXT, REQ_PROFILE_CARD_OPTIONS } from '../../Constants/Constants';
import { db } from '../../firebase';
import { rejectServiceOffer } from '../../Redux/actions/serviceActions';

const ProfileDetails = ({ uid, requestId, id, bookProvider, status }) => {
  const ref = useRef();
  // eslint-disable-next-line no-unused-vars
  const [modalPicture, setModalPicture] = useState();
  const [loading, setLoading] = useState(true);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [providerDetails, setProviderDetails] = useState({});
  const [rejected, setRejected] = useState(status === 'rejected');
  const [sureReject, setSureReject] = useState(false);
  const [rejectModalVisibility, setRejectModalVisibility] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (sureReject) {
      setLoading(true);
      dispatch(
        rejectServiceOffer(requestId, uid, (success) => {
          setLoading(false);
          if (success) {
            setRejected(true);
            setRejectModalVisibility(false);
          }
        })
      );
    }
  }, [sureReject]);

  const fetchProviderDetails = async () => {
    const providerDetailsRef = doc(db, 'provider_details', `${id}`);
    const docSnap = await getDoc(providerDetailsRef);

    if (docSnap.exists()) {
      setProviderDetails(docSnap.data());
    }
    setLoading(false);
  };
  const handleBooking = () => {
    bookProvider(providerDetails);
  };

  console.log("providerDetailsRef", providerDetails)

  useEffect(() => {
    fetchProviderDetails();
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (!modalVisibility && ref.current && !ref.current.contains(e.target)) {
        setModalVisibility(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [setModalVisibility]);

  if (loading) {
    return <h1>loading...</h1>;
  }

  const handleRejectOffer = () => {
    setRejectModalVisibility(true);
  };

  const logo = [groom, vet, train, board, dogwalk, sit];

  // console.log(providerDetails);

  return (
    <>
      <RejectPopUp
        modalVisibility={rejectModalVisibility}
        setModalVisibility={setRejectModalVisibility}
        reject={setSureReject}
      />
      {!rejected && (
        <Container className='my-14'>
          <hr className='mt-7' />
          <div className='grid sm:grid-cols-1 lg:grid-cols-2 mt-3 md:mt-16 gap-y-3 md:gap-y-7 gap-x-7'>
            <div>
              <h1 className='font-bold text-md mb-1 md:text-lg'>About Me</h1>
              {/* <p className='text-base'>{providerDetails.about_me}</p> */}
              <p className='text-base'>{REQ_PROFILE_CARD_OPTIONS[0].aboutMe}</p>

            </div>
            <div>
              <h1 className='font-bold text-md mb-1 md:text-lg'>
                Awards & Certificate
              </h1>
              <div className='grid grid-cols-4 gap-1'>
                {REQ_PROFILE_CARD_OPTIONS[0]?.certificates?.map((item, i) => (
                  <img
                    src={item}
                    key={i}
                    className='h-24 cursor-pointer'
                    onClick={() => {
                      setModalPicture(item);
                      setModalVisibility(true);
                    }}
                  />
                ))}
              </div>
            </div>
            <div>
              <div className='mb-7'>
                <h1 className='font-bold text-md mb-1 md:text-lg'>
                  Experience
                </h1>
                <h2 className='text-base font-number'>
                  {/* {providerDetails.experience || ''} */}
                  {REQ_PROFILE_CARD_OPTIONS[0].experiences}
                </h2>
              </div>
              <div>
                <h1 className='font-bold text-md md:text-lg mb-4'>
                  Other services
                </h1>
                <div className='grid grid-cols-1 gap-2'>

                  {providerDetails.active_services?.map((item, i) => (
                    <div
                      className='flex border-2 w-52 items-center border-solid border-action rounded-lg'
                      key={i}
                    >
                      {/* {
                        logo.map((imgSrc,i) => ( */}
                          <img src={logo[i]}  className='py-2 w-10 mx-1' />

                         {/* ))
                       } */}
                      <h1>{SERVICES_TEXT[item.service_slug]}</h1>
                    </div>
                  ))}

                  
                </div>
              </div>
            </div>
            <div>
              <h1 className='font-bold text-md mb-1 md:text-lg'>
                Service Photo and videos
              </h1>
              <div className='grid grid-cols-3 gap-1'>
                {REQ_PROFILE_CARD_OPTIONS[0]?.images?.map((item, i) => (
                  <img
                    src={item}
                    key={i}
                    className='h-32 w-full bg-center cursor-pointer'
                    onClick={() => {
                      setModalPicture(item);
                      setModalVisibility(true);
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className='flex justify-center gap-4 ml-5 md:ml-0 my-20'>
            <GradientButton title='BOOK NOW' onPress={() => handleBooking()} />
            <GradientButton
              title='REJECT'
              onPress={() => handleRejectOffer()}
              className='mr-4'
              grey
            />
          </div>
          <Modal visibilty={modalVisibility}>
            <div className='bg-white rounded-lg' ref={ref}>
              <img src={modalPicture} alt='' className='p-7 h-96 w-auto' />
            </div>
          </Modal>
        </Container>
      )}
    </>
  );
};

ProfileDetails.defaultProps = {
  id: null,
  uid: null,
  requestId: null,
  bookProvider: () => {},
  status: null,
};

ProfileDetails.propTypes = {
  id: PropTypes.number,
  bookProvider: PropTypes.func,
  requestId: PropTypes.string,
  uid: PropTypes.string,
  status: PropTypes.string,
};

export default ProfileDetails;
