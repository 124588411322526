import React, { useEffect, useState } from 'react'
import { Container, GradientButton } from '../Components'
import Insurance from '../Assets/icons/Insurance.png'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
const FailureScreen = () => {
  const { txnId } = useParams()
  const [insurance, setInsurance] = useState({})
  //$ txnId
  useEffect(() => {
    axios
      .post(
        'https://us-central1-pawpurrfect-stage.cloudfunctions.net/getInsuranceWithTxnid',
        {
          txnid: txnId,
        },
      )
      .then((res) => {
        setInsurance(res.data)
        console.log(res.data)
      })
      .catch((e) => console.log(e))
  }, [txnId])

  return (
    <>
      <Container className="my-16">
        <div className="grid grid-cols-1 text-center items-center">
          {/* <div className="flex justify-center mb-7">
            <img src={Insurance} className="h-24" />
          </div> */}
          <div>
            <h1 className="text-lg font-semibold">
              {/* Your request for {location.state.service} has been placed! */}
              Oops! Your payment failed
            </h1>
          </div>
          <div className="grid grid-cols-4">
            <div></div>
            <div className="col-span-2 mt-4 text-center">
              <p>
                We're sorry, we were unable to process your payment. <br/>Please try
                again later
              </p>
            </div>
            <div></div>
          </div>
          <div className="grid grid-cols-4">
            <div></div>
            <div className="col-span-2 mt-4 text-center bg-lightYellow p-2 rounded">
              {/* <p>*Please accept one of the responses to confirm booking.</p> */}
              <p>If you need help please contact us</p>
            </div>
            <div></div>
          </div>
          <div className="grid grid-cols-4 mt-10">
            <div></div>
            <div className="col-span-2 mt-4 text-center rounded">
              <GradientButton
                title="CLOSE"
                onPress={() => {
                  window.close()
                }}
              />
            </div>
            <div></div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default FailureScreen
