import PropTypes from "prop-types";
import React from "react";
import { TiTick } from "react-icons/ti";

const ChipCard = (props) => {
  const { Icon, title, onPress, disabled, fill, isSelected, ticked, isPetSelected } =
    props;
  return (
    <div
      className={`flex items-center border-solid border-2 cursor-pointer rounded p-2 md:p-4 ${
        !fill && isSelected && "border-action"
      } ${
        fill && !disabled && isSelected
          ? "bg-action border-action"
          : disabled && "bg-light cursor-default pointer-events-none border-light"
      }  ${fill && "flex justify-center h-full"}`}
      onClick={onPress}
    >
      {isPetSelected && Icon && <img src={Icon} alt="" className="w-8 mr-2" />}
      <h1
        className={`font-number text-base ${
          fill && !disabled && isSelected ? "text-white" : ""
        }`}
      >
        {title}
      </h1>
      <div
        className={`${
          isSelected && !fill && ticked ? "block" : "hidden"
        } justify-center w-6 h-6 flex items-center bg-action rounded-full ml-auto`}
      >
        <TiTick fill="white" size={16} />
      </div>
    </div>
  );
};

ChipCard.defaultProps = {
  Icon: null,
  onPress: () => {},
  timeSlotSection: false,
  disabled: false,
  fill: false,
  isSelected: false,
  ticked: false,
  isPetSelected: false,
};

ChipCard.propTypes = {
  Icon: PropTypes.string,
  title: PropTypes.string,
  onPress: PropTypes.func,
  timeSlotSection: PropTypes.bool,
  disabled: PropTypes.bool,
  fill: PropTypes.bool,
  isSelected: PropTypes.bool,
  ticked: PropTypes.bool,
  isPetSelected: PropTypes.bool,
};

export default ChipCard;
